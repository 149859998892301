import { FinalizeTreatmentActions } from "components/Treatments";
import { Formik, FormikConfig } from "formik";
import {
  clearSavedDefaultValues,
  getFinalizeTreatmentDefaultValues,
  getFinalizeTreatmentPayload,
  getFinalizeTreatmentValidationSchema,
  getMissingRequiredFields,
  getSavedDefaultValues,
} from "helpers";

import { Alert, AlertTitle } from "@material-ui/lab";
import { AxiosError } from "axios";
import Loader from "components/Loader";
import {
  TreatmentWidgetActions,
  TreatmentWidgetContent,
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
} from "layouts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { zohoActions, zohoSelectors } from "redux/zoho";
import { notifications, treatmentServices, zohoServices } from "services";
import {
  FinalizeMatchingProductMedicine,
  ZohoPharmacy,
  ZohoProduct,
  FinalizeZohoOtherTreatmentMedicine,
  FinalizeMatchingMedPackage,
  ZohoTreatmentMedicine,
  ZohoTreatmentTreatmentArea,
  ZohoTreatment,
  ZohoMedUsageUnit,
  ZohoClientRecord,
} from "types";
import {
  DefaultValuesStore,
  SetInitialValues,
} from "components/DefaultValuesStore";
import { Box } from "@material-ui/core";
import { FinalizeTreatmentForms } from "./FinalizeTreatmentForms";
import { BlockWidgetAlert } from "./BlockWidgetAlert";

const dummyValues = {
  products: [],
  pharmacies: [],
  matchingProducts: [],
  matchingPackages: [],
  otherTreatmentMedicines: [],
  billingCycleUnit: {
    value: "Months",
    title: "Every X Months",
  },
  supplementaryProducts: [],
  selectedPackage: "",
  treatmentMedicines: [],
};

export const FinalizeTreatment = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState("");
  const [id] = useSelector(zohoSelectors.getIds);
  const [activeTab, setActiveTab] = useState(0);
  const [matchingPackages, setMatchingPackages] = useState<
    FinalizeMatchingMedPackage[]
  >([]);
  const [matchingProducts, setMatchingProducts] = useState<
    FinalizeMatchingProductMedicine[]
  >([]);
  const [pharmacies, setPharmacies] = useState<ZohoPharmacy[]>([]);
  const [supplementaryProducts, setSupplementaryProducts] = useState<
    ZohoProduct[]
  >([]);
  const user = useSelector(zohoSelectors.getCurrentUser);
  const [productsUrl, setProductsUrl] = useState("");
  const [isFetchedAllData, setIsFetchedAllData] = useState(false);
  const [bacteriostaticProducts, setBacteriostaticProducts] = useState<
    ZohoProduct[]
  >([]);
  const [otherTreatmentMedicines, setOtherTreatmentMedicines] = useState<
    FinalizeZohoOtherTreatmentMedicine[]
  >([]);
  const [otherProducts, setOtherProducts] = useState<ZohoProduct[]>([]);
  const [suggestedMedicines, setSuggestedMedicines] = useState<
    (ZohoTreatmentMedicine & { treatmentArea: ZohoTreatmentTreatmentArea })[]
  >([]);
  const [treatment, setTreatment] = useState<ZohoTreatment | null>(null);
  const [medUsageUnits, setMedUsageUnits] = useState<ZohoMedUsageUnit[]>([]);
  const [client, setClient] = useState<ZohoClientRecord | null>(null);
  const [savedDefaultValues, setSavedDefaultValues] = useState<Record<
    string,
    any
  > | null>(null);

  const treatmentTreatmentAreasById = useMemo(() => {
    return suggestedMedicines.reduce<
      Record<string, ZohoTreatmentTreatmentArea>
    >((total, current) => {
      return { ...total, [current.treatmentArea.id]: current.treatmentArea };
    }, {});
  }, [suggestedMedicines]);

  const initialValues = useMemo(() => {
    if (!isFetchedAllData || !treatment) {
      return dummyValues;
    }
    const savedDefaultValue = getSavedDefaultValues(
      "finalize-treatment",
      id,
      [treatment.Modified_Time]
        .concat(
          matchingProducts.map(
            (matchingProduct) => matchingProduct.Modified_Time
          )
        )
        .concat(
          matchingPackages.reduce<string[]>(
            (total, matchingPackage) =>
              total.concat(
                matchingPackage.products.map(
                  (p) =>
                    p.matchingMedProduct.matchingMedProduct.treatmentMedicine
                      .Modified_Time
                )
              ),
            []
          )
        )
        .concat(otherTreatmentMedicines.map((o) => o.Modified_Time))
        .concat(suggestedMedicines.map((s) => s.Modified_Time))
    );

    setImmediate(() => {
      setSavedDefaultValues(savedDefaultValue);
    });
    return getFinalizeTreatmentDefaultValues(
      pharmacies,
      matchingProducts,
      otherTreatmentMedicines,
      matchingPackages,
      suggestedMedicines,
      bacteriostaticProducts,
      treatmentTreatmentAreasById
    );
  }, [
    isFetchedAllData,
    pharmacies,
    matchingProducts,
    otherTreatmentMedicines,
    matchingPackages,
    suggestedMedicines,
    bacteriostaticProducts,
    id,
    treatment,
    treatmentTreatmentAreasById,
  ]);

  const isNoCombination = useMemo(() => {
    if (!matchingPackages.length && !matchingProducts.length) {
      return true;
    }

    if (matchingPackages.length && matchingProducts.length) {
      return activeTab === 2;
    }

    if (matchingPackages.length || matchingProducts.length) {
      return activeTab === 1;
    }

    return false;
  }, [matchingPackages.length, matchingProducts.length, activeTab]);

  const isMatchingProducts = useMemo(() => {
    if (!matchingProducts.length) {
      return false;
    }
    if (matchingPackages.length) {
      return activeTab === 1;
    }
    return activeTab === 0;
  }, [matchingPackages.length, matchingProducts.length, activeTab]);

  const isMatchingPackages = useMemo(
    () => !!matchingPackages.length && activeTab === 0,
    [matchingPackages.length, activeTab]
  );

  const validationSchema = useMemo(() => {
    if (!isFetchedAllData || !treatment?.Treatment_Period_in_Months) {
      return null;
    }
    return getFinalizeTreatmentValidationSchema(
      isNoCombination,
      isMatchingProducts,
      isMatchingPackages,
      treatment.Treatment_Period_in_Months,
      matchingProducts
    );
  }, [
    isMatchingPackages,
    isMatchingProducts,
    isNoCombination,
    treatment?.Treatment_Period_in_Months,
    isFetchedAllData,
    matchingProducts,
  ]);

  const missingRequiredFields = useMemo(() => {
    return getMissingRequiredFields(client);
  }, [client]);

  const hasMissingRequiredFields = useMemo(
    () => !!missingRequiredFields.length,
    [missingRequiredFields.length]
  );

  const view = useMemo(() => {
    if (fetchError) {
      return "fetch-error";
    }
    if (hasMissingRequiredFields) {
      return "initial-block";
    }

    return "form";
  }, [fetchError, hasMissingRequiredFields]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const [res] = await Promise.all([
        treatmentServices.fetchFinalizeTreatmentWidgetDetails(id),
        dispatch(zohoActions.fetchCurrentUser()),
      ]);
      setMatchingPackages(
        res.treatmentMedicinesForMatchingPackages?.mappedMatchingMedPackages ||
          []
      );
      setMatchingProducts(res.treatmentMedicinesForMatchingProducts || []);
      if (res.pharmacies) {
        setPharmacies(res.pharmacies);
      }
      setProductsUrl(res.productUrl);
      setBacteriostaticProducts(res.bacteriostaticProducts);
      setSupplementaryProducts(res.supplementaryProducts);
      setOtherTreatmentMedicines(res.otherTreatmentMedicines);
      setOtherProducts(res.otherProducts);
      setSuggestedMedicines(res.treatmentMedicines || []);
      setTreatment(res.treatment);
      if (res.usageUnits) {
        setMedUsageUnits(res.usageUnits || []);
      }
      const fetchUsageUnits = async () => {
        if (!res.usageUnits) {
          const medUsageUnitsRes = await zohoServices.getZohoRecords(
            "Meds_Usage_Units",
            ""
          );
          setMedUsageUnits((medUsageUnitsRes as unknown) as ZohoMedUsageUnit[]);
        }
      };
      const fetchClient = async () => {
        if (res.treatment.Contact?.id) {
          const clientRes = await zohoServices.getClientRecords({
            ids: [res.treatment.Contact.id],
          });
          if (clientRes && clientRes[0]) {
            setClient(clientRes[0]);
          }
        }
      };
      await Promise.all([fetchUsageUnits(), fetchClient()]);

      setIsFetchedAllData(true);
    } catch (error) {
      const err = error as AxiosError;
      setFetchError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [id, dispatch]);

  const submitDetails = useCallback(
    async (values: any) => {
      try {
        if (!treatment?.id || !user?.id) {
          return;
        }

        const payload = getFinalizeTreatmentPayload(
          values,
          treatment.id,
          user.id,
          isNoCombination,
          isMatchingProducts,
          isMatchingPackages,
          matchingProducts
        );
        setLoading(true);
        await treatmentServices.finalizeTreatment(payload);
        clearSavedDefaultValues("finalize-treatment", id);
        zohoServices.closePopup(true);
      } catch (error) {
        const err = error as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [
      matchingProducts,
      treatment?.id,
      user?.id,
      id,
      isMatchingPackages,
      isMatchingProducts,
      isNoCombination,
    ]
  );

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);
      await submitDetails(values);
      setSubmitting(false);
    },
    [submitDetails]
  );

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
    >
      <TreatmentWidgetLayout defaultWidth={1200}>
        <TreatmentWidgetTitle title="Review and Finalize Treatment" />
        <TreatmentWidgetContent>
          <Loader open={loading} />
          {view === "fetch-error" ? (
            <Alert severity="error">
              <AlertTitle>An error occurred while fetching data.</AlertTitle>
              {fetchError}
            </Alert>
          ) : null}
          <DefaultValuesStore widgetName="finalize-treatment" identifier={id} />
          {savedDefaultValues && isFetchedAllData ? (
            <SetInitialValues savedDefaultValues={savedDefaultValues} />
          ) : null}
          {view === "form" && !isFetchedAllData ? (
            <Box style={{ height: 700 }}>{/*  */}</Box>
          ) : null}
          {view === "initial-block" && isFetchedAllData ? (
            <BlockWidgetAlert missingRequiredFields={missingRequiredFields} />
          ) : null}
          {view === "form" && isFetchedAllData ? (
            <FinalizeTreatmentForms
              matchingPackages={matchingPackages}
              matchingProducts={matchingProducts}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              pharmacies={pharmacies}
              productsUrl={productsUrl}
              bacteriostaticProducts={bacteriostaticProducts}
              supplementaryProducts={supplementaryProducts}
              otherTreatmentMedicines={otherTreatmentMedicines}
              otherProducts={otherProducts}
              suggestedMedicines={suggestedMedicines}
              medUsageUnits={medUsageUnits}
              clientMobile={client?.Mobile}
              clientMobile2={client?.Phone}
              treatmentTreatmentAreasById={treatmentTreatmentAreasById}
              isMatchingPackages={isMatchingPackages}
              isMatchingProducts={isMatchingProducts}
              isNoCombination={isNoCombination}
            />
          ) : null}
        </TreatmentWidgetContent>
        {isFetchedAllData ? (
          <TreatmentWidgetActions>
            <FinalizeTreatmentActions
              view={view}
              isNoCombination={isNoCombination}
              isMatchingProducts={isMatchingProducts}
              isMatchingPackages={isMatchingPackages}
              matchingProducts={matchingProducts}
            />
          </TreatmentWidgetActions>
        ) : null}
      </TreatmentWidgetLayout>
    </Formik>
  );
};
