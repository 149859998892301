/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { filterNeedleOptions, needleOptions } from "./constants";
import {
  FormattedOrderItem,
  GetTreatmentOrderDetailsResponse,
  GetUpdatedInitialValuesPayload,
  OrderItem,
  OrderItemPrescriptionItemPair,
  PrescriptionItem,
  SubmitPayload,
} from "./types";

export const getInjectionBundleQuantityString = (doses: number) => {
  return `${doses ? doses + 2 : 2} (${doses || 2} doses + 2 extra)`;
};

export const getAlcoholWipesQuantityString = (doses: number) => {
  const finalValue = doses % 2 === 0 ? (doses + 2) / 2 : (doses + 1) / 2;
  return `${finalValue} pack${finalValue > 1 ? "s" : ""} (${
    doses || 2
  } doses + ${finalValue % 2 === 0 ? "2" : "1"} extra)`;
};

export const constructOrderItemPrescriptionItemPairs = (
  treatmentOrderItems: OrderItem[],
  prescriptionItems: PrescriptionItem[]
): {
  orderItemPrescriptionItemPairs: OrderItemPrescriptionItemPair[];
  everyItemHasPair: boolean;
  formattedOrderItems: FormattedOrderItem[];
} => {
  const orderItemPrescriptionItemPairs: OrderItemPrescriptionItemPair[] = [];

  treatmentOrderItems.forEach((currentOrderItem) => {
    const resultObj: OrderItemPrescriptionItemPair = {
      orderItem: currentOrderItem,
    };
    prescriptionItems.forEach((currentPrescriptionItem) => {
      if (currentPrescriptionItem.Product.id === currentOrderItem.Product.id) {
        resultObj.prescriptionItem = currentPrescriptionItem;
      }
    });
    orderItemPrescriptionItemPairs.push(resultObj);
  });

  const everyItemHasPair = orderItemPrescriptionItemPairs.every(
    (i) => Object.keys(i).length === 2
  );

  const formattedOrderItems: FormattedOrderItem[] = orderItemPrescriptionItemPairs.map(
    (i) => {
      let remainingQty;
      const associatedPresciptionItem = i.prescriptionItem;

      const prescriptionTotalQty =
        associatedPresciptionItem?.Quantity_To_Dispense || undefined;
      let prescriptionTotalDispensedQty =
        associatedPresciptionItem?.Quantity_Dispensed || undefined;
      const usageNotes =
        i.orderItem.Usage_Note || associatedPresciptionItem?.Usage_Notes || "";
      const hasBundle = i.orderItem.Product.Product_Type === "Injection Bundle";
      const quantityInOrder = hasBundle
        ? `${i.orderItem.Quantity} Bundle`
        : `${i.orderItem.Quantity} x ${
            i.orderItem.Product.Usage_Units_Per_Sellable_Unit
          } ${i.orderItem.Product.Med_Usage_Unit?.name || ""}`;
      let duration: string | null = null;
      const durationPeriod = i.orderItem.Duration_Period;
      const durationUnit = i.orderItem.Duration_Unit || "";
      const itemName = i.orderItem.Item.name;
      const id = i.orderItem.id;
      const isHCG = !!(
        i.orderItem.Product.Extra_Syringe_Volume_ml &&
        i.orderItem.Product.Extra_Injection_Type
      );
      const extraDoses =
        i.orderItem.Quantity /
          (i.orderItem.Product.Usage_Units_Per_Sellable_Unit ||
            i.orderItem.Quantity) +
        2;
      let bundleDetails: any = {};

      if (prescriptionTotalQty) {
        prescriptionTotalDispensedQty = prescriptionTotalDispensedQty || 0;
        remainingQty =
          Number(prescriptionTotalQty) - Number(prescriptionTotalDispensedQty);
      }

      if (durationPeriod && durationUnit) {
        duration = `${durationPeriod} ${durationUnit}`;
      }

      if (hasBundle) {
        bundleDetails = {
          id,
          itemName,
          syringeVolume: i.orderItem.Product.Syringe_Volume_ml,
          syringeType: i.orderItem.Product.Injection_Type,
          needleFilter:
            i.orderItem.Needle_Filter || filterNeedleOptions[0].value,
          needle: i.orderItem.Needle || needleOptions[0].value,
          includeAlcoholWipes: i.orderItem.Include_Alcohol_Wipes || true,
          numberOfDoses: undefined,
          usedWith: i.orderItem.Product.Used_With?.name || null,
          extraSyringeVolume: i.orderItem.Product?.Extra_Syringe_Volume_ml?.join(
            " or "
          ),
          extraSyringeType: i.orderItem.Product.Extra_Injection_Type,
          extraNeedle:
            i.orderItem?.Extra_Needle ||
            i.orderItem.Needle ||
            needleOptions[0].value,
          extraNeedleFilter:
            i.orderItem.Extra_Needle_Filter ||
            i.orderItem.Needle_Filter ||
            filterNeedleOptions[0].value,
          isHCG,
          extraDoses,
          hasBundle,
        };
      }

      return {
        Item: {
          id: i.orderItem.Product.id,
          Product_Type: i.orderItem.Product.Product_Type,
        },
        Matching_Prescription_Item: {
          id: i.prescriptionItem?.id || null,
        },
        id,
        itemName,
        prescriptionTotalQty,
        prescriptionTotalDispensedQty,
        remainingQty,
        quantityInOrder,
        usageNotes,
        hasBundle,
        bundleDetails,
        duration,
        durationPeriod,
        durationUnit,
      };
    }
  );

  return {
    orderItemPrescriptionItemPairs,
    everyItemHasPair,
    formattedOrderItems,
  };
};

export const getFormattedOrderDetailsData = (
  data: GetTreatmentOrderDetailsResponse
) => {
  const sortedLegacyTreatments = data.legacyTreatments.sort((a, b) => {
    const dateA = a.Start_Of_Prescription_Date
      ? new Date(a.Start_Of_Prescription_Date).getTime()
      : null;
    const dateB = b.Start_Of_Prescription_Date
      ? new Date(b.Start_Of_Prescription_Date).getTime()
      : null;

    if (dateA === null && dateB === null) {
      return 0;
    }
    if (dateA === null) {
      return 1;
    }
    if (dateB === null) {
      return -1;
    }

    return dateB - dateA;
  });

  const legacyTreatments = sortedLegacyTreatments.map((t) => {
    const name = `${t.Name}`;
    const date = t.Start_Of_Prescription_Date
      ? `- ${t.Start_Of_Prescription_Date}`
      : "";
    const doctor = t.Associated_Doctor?.name
      ? `- ${t.Associated_Doctor.name}`
      : "";

    return {
      id: t.id,
      Name: t.Name,
      Start_Of_Prescription_Date: t.Start_Of_Prescription_Date,
      Associated_Doctor: t.Associated_Doctor,
      WebUrl: t.WebUrl,
      title: `${name} ${date} ${doctor}`,
      label: `${name} ${date} ${doctor}`,
      value: t.id,
    };
  });

  const sortedSignedLegacyPrescriptions = data.signedLegacyPrescriptions.sort(
    (a, b) => {
      const dateA = a.zohosign__Date_Completed
        ? new Date(a.zohosign__Date_Completed).getTime()
        : null;
      const dateB = b.zohosign__Date_Completed
        ? new Date(b.zohosign__Date_Completed).getTime()
        : null;

      if (dateA === null && dateB === null) {
        return 0;
      }
      if (dateA === null) {
        return 1;
      }
      if (dateB === null) {
        return -1;
      }

      return dateB - dateA;
    }
  );

  const signedLegacyPrescriptions = sortedSignedLegacyPrescriptions.map((p) => {
    return {
      id: p.id,
      Date_Completed: p.zohosign__Date_Completed,
      Name: p.Name,
      zohosign__Date_Completed: p.zohosign__Date_Completed,
      title: p.Name,
      label: p.Name,
      value: p.id,
      WebUrl: p.WebUrl,
      prescriptionsLookUp: p.prescriptionsLookUp,
    };
  });

  let prescriptionItems = [];

  if (data.treatmentOrder?.Prescription) {
    prescriptionItems = data.treatmentOrder.Prescription.Prescription_Items;
  } else {
    const signedDoc = signedLegacyPrescriptions[0];
    prescriptionItems =
      signedDoc?.prescriptionsLookUp?.[0]?.Prescription_Items || [];
  }

  const invoice = data.treatmentOrder.Invoice;
  const recurringInvoice = data.treatmentOrder.Recurring_Invoice;

  const patient = data.treatmentOrder.Patient;
  const doctor = data.treatmentOrder.Doctor;

  const street = data.treatmentOrder.Street;
  const city = data.treatmentOrder.City;
  const provinceRegionState = data.treatmentOrder.Province_Region_State;
  const postalCode = data.treatmentOrder.Postal_Code_Zip;
  const country = data.treatmentOrder.Country;

  const shippingAddress = {
    street,
    city,
    provinceRegionState,
    postalCode,
    country,
  };

  const orderDetails = {
    invoice,
    recurringInvoice,
    patient,
    doctor,
  };

  return {
    orderDetails,
    shippingAddress,
    legacyTreatments,
    signedLegacyPrescriptions,
    prescriptionItems,
    treatmentOrderItems: data.treatmentOrderItems,
  };
};

export const getUpdatedInitialValues = ({
  orderDetails,
  shippingAddress,
  legacyTreatments,
  signedLegacyPrescriptions,
  prescriptionItems,
  formattedOrderItems,
  everyItemHasPair,
  orderItemPrescriptionItemPairs,
}: GetUpdatedInitialValuesPayload) => {
  return {
    legacyTreatment: legacyTreatments[0],
    legacyTreatments,
    legacyPrescription: signedLegacyPrescriptions[0],
    legacyPrescriptions: signedLegacyPrescriptions,
    formattedOrderItems,
    prescriptionItems,
    orderDetails,
    shippingAddress,
    everyItemHasPair,
    orderItemPrescriptionItemPairs,
  };
};

export const getSubmitPayload = ({
  formattedOrderItems,
  legacyTreatment,
  legacyPrescription,
  treatmentOrderId,
}: SubmitPayload) => {
  const treatmentOrders = formattedOrderItems.map((o) => {
    const numberOfDoses = o.bundleDetails?.numberOfDoses;
    const syringeQuantity = numberOfDoses ? numberOfDoses + 2 : null;
    let alcoholWipesQuantity: number | null;

    if (numberOfDoses) {
      if (numberOfDoses % 2 === 0) {
        alcoholWipesQuantity = (numberOfDoses + 2) / 2;
      } else {
        alcoholWipesQuantity = (numberOfDoses + 1) / 2;
      }
    } else {
      alcoholWipesQuantity = null;
    }

    return {
      id: o.id,
      Item: o.Item,
      Duration_Unit: o.durationUnit,
      Duration_Period: o.durationPeriod,
      Matching_Prescription_Item: o.Matching_Prescription_Item?.id
        ? o.Matching_Prescription_Item
        : null,
      Prescription_Total_Quantity: o.prescriptionTotalQty,
      Prescription_Total_Dispensed_Quantity: o.prescriptionTotalDispensedQty,
      Usage_Notes: o.usageNotes,
      Syringes_Quantity: syringeQuantity,
      Needle: o.bundleDetails?.needle || null,
      Needle_Filter: o.bundleDetails?.needleFilter || null,
      Needle_Filters_Quantity: syringeQuantity,
      Needles_Quantity: syringeQuantity,
      Include_Alcohol_Wipes: o.bundleDetails?.includeAlcoholWipes ?? null,
      Alcohol_Wipes_Quantity: o.bundleDetails?.includeAlcoholWipes
        ? alcoholWipesQuantity
        : null,
      Extra_Syringes_Quantity: o.bundleDetails?.isHCG ? syringeQuantity : null,
      Extra_Needle_Filters_Quantity: o.bundleDetails?.isHCG
        ? syringeQuantity
        : null,
      Extra_Needle: o.bundleDetails?.isHCG
        ? o.bundleDetails?.extraNeedle
        : null,
      Extra_Needles_Quantity: o.bundleDetails?.isHCG ? syringeQuantity : null,
    };
  });

  const prescriptionsLookUp = legacyPrescription?.prescriptionsLookUp[0]?.id
    ? {
        id: legacyPrescription.prescriptionsLookUp[0].id,
      }
    : null;

  const payload = {
    treatmentOrder: {
      id: treatmentOrderId,
    },
    selectedLegacyTreatment: {
      id: legacyTreatment.id,
    },
    selectedSignedLegacyPrescription: {
      id: legacyPrescription.id,
      Date_Completed: legacyPrescription.Date_Completed,
      prescriptionsLookUp,
    },
    treatmentOrderItems: treatmentOrders,
  };

  return payload;
};
