export const ROUTES = {
  contacts: {
    assignMcm: "/contacts/assign-mcm",
    emailLogs: "/contacts/email-logs",
  },
  pathway: {
    details: {
      generateForm: "/pathway/details/generate-form",
      generateFormBlueprint: "/pathway/details/generate-form-blueprint",
      tubeLabel: "/pathway/details/tube-label",
      sendInstructions: "/pathway/details/send-instructions",
      sendInstructionsBlueprint: "/pathway/details/send-instructions-blueprint",
      sendNSInstructionsBlueprint:
        "/pathway/details/send-ns-instructions-blueprint",
      specifyDetailsBlueprint: "/pathway/details/specify-details-blueprint",
      specifyDetails: "/pathway/details/specify-details",
      cancelRetestArrangementBlueprint:
        "/pathway/details/cancel-retest-arrangement-blueprint",
    },
    list: {
      generateForm: "/pathway/list/generate-form",
      tubeLabel: "/pathway/list/tube-label",
    },
    testResult: "/pathway/test-result",
  },
  unmatched: "/unmatched",
  unmatchedComparisonWidget: "/unmatched-comparison-widget",
  recordAccessLogs: "/record-access-logs",
  settings: {
    // obr: "/settings/obr",
    obx: "/settings/obx",
    failure: "/settings/failure",
    abnormal: "/settings/abnormal",
    tubes: "/settings/tubes",
    emailConfig: "/settings/email-config",
    templates: "/settings/templates",
    mcm: "/settings/mcm",
    mcmCall: "/settings/mcm-call",
    emailLogs: "/settings/email-logs",
    checkoutPage: "/settings/checkout-page",
    recordAccessLogs: "/settings/record-access-logs",
  },
  testResult: {
    list: "/test-results",
    details: {
      retestRequired: {
        notClientError: "/test-results/details/retest/not-client-error",
        clientError: "/test-results/details/retest/client-error",
        generateInvoice: "/test-results/details/retest/generate-invoice",
        approveChargingClientForRetest:
          "/test-results/details/retest/approve-charging-client-for-retest",
      },
    },
  },
  clientAdmin: "/client-admin",
  testProfileDelete: "/test-profile/delete",
  confirmKitPacking: "/confirm-kit-packing",
  confirmInvoiceCreation: "/confirm-invoice-creation",
  waiveChargingClientForRetest: "/waive-charging-client-for-retest",
  confirmKitInvoiceCreation: "/confirm-kit-invoice-creation",
  pendingBloodDrawerInvoiceBluePrint: {
    confirmKitInvoiceCreation:
      "/pending-blood-drawer-invoice-blue-print/confirm-kit-invoice-creation",
    generateInvoice:
      "/pending-blood-drawer-invoice-blue-print/generate-invoice",
  },
  treatments: {
    create: "/treatments/create",
    complete: "/treatments/complete",
    completeForBlueprint: "/treatments/complete-for-blueprint",
    update: "/treatments/update",
    updateForBlueprint: "/treatments/update-for-blueprint",
    migrate: "/treatments/migrate",
    finalize: "/treatments/finalize",
    startPharmacySelection: "/treatments/start-pharmacy-selection",
    requestChanges: "/treatments/request-changes",
    startPrescriptionRenewal: "/treatments/start-prescription-renewal",
  },
  signCttp: "/sign-cttp",
  notifyDoctor: "/notify-doctor",
  signVenesectionTreatment: "/sign-venesection-treatment",
  createVenesectionTreatment: "/create-venesection-treatment",
  createManualOrder: "/create-manual-order",
  pauseTreatment: "/pause-treatment",
  resumeTreatment: "/resume-treatment",
  cancelTreatment: "/cancel-treatment",
  cancelTreatmentOrder: "/cancel-treatment-order",
  createReplacmentOrder: "/create-replacement-order",
  medicationDisposal: "/medication-disposal",
  createManualPrescription: "/create-manual-prescription",
  clientCheckup: "/client-checkup",
  renewTreatment: "/renew-treatment",
  bulkOrderPacking: "/bulk-order-packing",
  bulkOrderManifest: "/bulk-order-manifest",
  confirmOrderPacking: "/confirm-order-packing",
  updateUpcomingOrders: "/update-upcoming-orders",
  dispenseDrugs: {
    dispenseOrder: "/dispense-drugs/dispense-order",
    performFinalChecks: "/dispense-drugs/perform-final-checks",
    printPackingSlip: "/dispense-drugs/print-packing-slip",
    addProductVariant: "/dispense-drugs/add-product-variant",
    requestDoctorClarification: "/dispense-drugs/request-doctor-clarification",
    provideClarifications: "/dispense-drugs/provide-clarifications",
  },
  completeOrderDetails: "/complete-order-details",
  documents: "/documents",
  waiveShippingCharges: "/waive-shipping-charges",
};
