import { Formik, FormikConfig, FormikErrors, FormikHelpers } from "formik";
import {
  clearSavedDefaultValues,
  getDoctorName,
  getMissingRequiredFields,
  getSavedDefaultValues,
  getUpdateTreatmentDefaultValues,
  getUpdateTreatmentPayload,
  getUpdateTreatmentValidationSchema,
  isStringEqual,
  validateTreatmentForm,
} from "helpers";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { zohoActions, zohoSelectors } from "redux/zoho";
import { notifications, treatmentServices, zohoServices } from "services";
import {
  ZohoBMHDoctor,
  ZohoClientRecord,
  ZohoDiagnosisOption,
  ZohoDosingAdminRoute,
  ZohoDosingType,
  ZohoMedicineAdminRoute,
  ZohoMedUsageUnit,
  ZohoPharmacy,
  ZohoProduct,
  ZohoSelectedProduct,
  ZohoTreatment,
  ZohoTreatmentArea,
  ZohoTreatmentMedicine,
  ZohoTreatmentOrder,
  ZohoTreatmentTreatmentArea,
} from "types";
import { AxiosError } from "axios";
import {
  TreatmentWidgetContent,
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
} from "layouts";
import Loader from "components/Loader";
import { Alert, AlertTitle } from "@material-ui/lab";

import { Box } from "@material-ui/core";
import {
  DefaultValuesStore,
  SetInitialValues,
} from "components/DefaultValuesStore";
import { BlockWidgetAlert } from "./BlockWidgetAlert";
import { UpdateTreatmentForm } from "./UpdateTreatmentForm";
import { UpdateTreatmentActions } from "./UpdateTreatmentActions";
import { FormsDialog } from "./FormsDialog";
import { NewTreatmentPlanInfo } from "./NewTreatmentPlanInfo";

interface Props {
  isCustomButton?: boolean;
}

const dummyValues = {
  billingCycleUnit: null,
  changeFirstInvoiceDateAfterSigning: false,
  diagnosis: [],
  doctor: null,
  firstPaymentDate: null,
  instructions: "",
  pharmacies: [],
  reasonForChanges: "",
  supplementaryProducts: [],
  treatmentMedicines: [],
  treatmentPeriod: null,
  treatmentTreatmentAreas: [],
};

export const UpdateTreatmentPlan = ({ isCustomButton = false }: Props) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [treatmentIsLegacy, setTreatmentIsLegacy] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const user = useSelector(zohoSelectors.getCurrentUser);
  const [id] = useSelector(zohoSelectors.getIds);
  const [treatment, setTreatment] = useState<ZohoTreatment | null>(null);
  const [doctors, setDoctors] = useState<ZohoBMHDoctor[]>([]);
  const [
    newTreatmentPlan,
    setNewTreatmentPlan,
  ] = useState<ZohoTreatment | null>(null);
  const [inProgressTreatmentOrders, setInProgressTreatmentOrders] = useState<
    ZohoTreatmentOrder[]
  >([]);

  const [previousState, setPreviousState] = useState("");

  const [treatmentAreas, setTreatmentAreas] = useState<ZohoTreatmentArea[]>([]);
  const [dosingAdminRoutes, setDosingAdminRoutes] = useState<
    ZohoDosingAdminRoute[]
  >([]);
  const [supplementaryProducts, setSupplementaryProducts] = useState<
    ZohoProduct[]
  >([]);
  const [
    selectedSupplementaryProducts,
    setSelectedSupplementaryProducts,
  ] = useState<ZohoSelectedProduct[]>([]);
  const [medicineAdminRoutes, setMedicineAdminRoute] = useState<
    ZohoMedicineAdminRoute[]
  >([]);
  const [
    isSuccessWithNoNewTreatmentPlan,
    setIsSuccessWithNoNewTreatmentPlan,
  ] = useState(false);
  const [dosingTypes, setDosingTypes] = useState<ZohoDosingType[]>([]);
  const [newTreatmentDoctor, setNewTreatmentDoctor] = useState("");
  const [doctor, setDoctor] = useState<ZohoBMHDoctor | null>(null);
  const [client, setClient] = useState<ZohoClientRecord | null>(null);
  const [treatmentTreatmentAreas, setTreatmentTreatmentAreas] = useState<
    ZohoTreatmentTreatmentArea[]
  >([]);
  const [diagnosisOptions, setDiagnosisOptions] = useState<
    ZohoDiagnosisOption[]
  >([]);
  const [suggestedMedicines, setSuggestedMedicines] = useState<
    ZohoTreatmentMedicine[]
  >([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isSelectedForm, setIsSelectedForm] = useState(false);
  const [pharmacies, setPharmacies] = useState<ZohoPharmacy[]>([]);
  const [bacteriostaticProducts, setBacteriostaticProducts] = useState<
    ZohoProduct[]
  >([]);
  const [allValuesFetched, setAllValuesFetched] = useState(false);
  const [valuesChangeCalculation, setValuesChangeCalculation] = useState({
    requirePackageAndPharmacySelection: false,
    requirePrescriptionAndCttpChange: false,
    requirePrescriptionChange: false,
  });
  const [savedDefaultValues, setSavedDefaultValues] = useState<Record<
    string,
    any
  > | null>(null);
  const [medUsageUnits, setMedUsageUnits] = useState<ZohoMedUsageUnit[]>([]);

  const initialValues = useMemo(() => {
    if (!allValuesFetched || !treatment) {
      return dummyValues;
    }
    const savedDefaultValue = getSavedDefaultValues(
      "update-treatment",
      id,
      [treatment.Modified_Time]
        .concat(treatmentTreatmentAreas.map((t) => t.Modified_Time))
        .concat(selectedSupplementaryProducts.map((s) => s.Modified_Time))
        .concat(suggestedMedicines.map((s) => s.Modified_Time))
        .concat(
          suggestedMedicines.reduce<string[]>((total, suggestedMedicine) => {
            const suggestedMedicineModifiedTimes: string[] = [
              suggestedMedicine.Modified_Time,
            ];

            if (suggestedMedicine.product?.Modified_Time) {
              suggestedMedicineModifiedTimes.push(
                suggestedMedicine.product?.Modified_Time
              );
            }

            if (suggestedMedicine.product?.injectionBundle?.Modified_Time) {
              suggestedMedicineModifiedTimes.push(
                suggestedMedicine.product?.injectionBundle?.Modified_Time
              );
            }

            if (suggestedMedicine.product?.bacteriostatic?.Modified_Time) {
              suggestedMedicineModifiedTimes.push(
                suggestedMedicine.product?.bacteriostatic?.Modified_Time
              );
            }

            return total.concat(suggestedMedicineModifiedTimes);
          }, [])
        )
    );
    setImmediate(() => {
      setSavedDefaultValues(savedDefaultValue);
    });
    return getUpdateTreatmentDefaultValues(
      treatment,
      doctors,
      client,
      treatmentTreatmentAreas,
      suggestedMedicines,
      treatmentAreas,
      pharmacies,
      selectedSupplementaryProducts,
      diagnosisOptions
    );
  }, [
    client,
    doctors,
    treatmentTreatmentAreas,
    suggestedMedicines,
    treatmentAreas,
    pharmacies,
    allValuesFetched,
    selectedSupplementaryProducts,
    treatment,
    id,
    diagnosisOptions,
  ]);

  const isDoctorNotAllowed = useMemo(() => {
    return !!doctor?.id && doctor?.id !== treatment?.Doctor?.id;
  }, [doctor?.id, treatment?.Doctor?.id]);

  const missingRequiredFields = useMemo(() => {
    return getMissingRequiredFields(client);
  }, [client]);

  const hasMissingRequiredFields = useMemo(
    () => !!missingRequiredFields.length,
    [missingRequiredFields.length]
  );

  const view = useMemo(() => {
    if (fetchError) {
      return "fetch-error";
    }

    if (newTreatmentPlan || isSuccessWithNoNewTreatmentPlan) {
      return "success";
    }

    if (treatmentIsLegacy || isDoctorNotAllowed || hasMissingRequiredFields) {
      return "initial-block";
    }

    if (!isSelectedForm && !doctor?.id && allValuesFetched) {
      return "form-dialog";
    }
    return "form";
  }, [
    fetchError,
    newTreatmentPlan,
    treatmentIsLegacy,
    isDoctorNotAllowed,
    isSelectedForm,
    hasMissingRequiredFields,
    doctor?.id,
    allValuesFetched,
    isSuccessWithNoNewTreatmentPlan,
  ]);

  const paidTreatmentOrders = useMemo(() => {
    return inProgressTreatmentOrders.filter((o) => {
      const hasUrl = !!o.recordCrmLink;
      const isPaid = o.Payment_Status === "Paid";
      const isValidState = ![
        "Delivered",
        "Returned",
        "Disposed",
        "Cancelled",
        "Prescription Sent",
      ].includes(o.State);
      return hasUrl && isPaid && isValidState;
    });
  }, [inProgressTreatmentOrders]);

  const paidTreatmentOrdersMapped = useMemo(() => {
    return paidTreatmentOrders.map(({ recordCrmLink, Name }) => ({
      recordCrmLink,
      Name,
    }));
  }, [paidTreatmentOrders]);

  const hasPaidTreatmentOrders = useMemo(() => {
    return paidTreatmentOrders.length > 0;
  }, [paidTreatmentOrders.length]);

  const validationSchema = useMemo(() => {
    if (!allValuesFetched || !treatment?.Treatment_Period_in_Months) {
      return null;
    }
    return getUpdateTreatmentValidationSchema(
      activeTab,
      hasPaidTreatmentOrders,
      treatment?.Treatment_Period_in_Months,
      valuesChangeCalculation
    );
  }, [
    hasPaidTreatmentOrders,
    activeTab,
    valuesChangeCalculation,
    treatment?.Treatment_Period_in_Months,
    allValuesFetched,
  ]);

  const treatmentTreatmentAreasById = useMemo(() => {
    return treatmentTreatmentAreas.reduce((total, current) => {
      return { ...total, [current.id]: current };
    }, {});
  }, [treatmentTreatmentAreas]);

  const otherMedicineTypeId = useMemo(() => {
    const otherTreatmentArea = treatmentAreas.find((t) =>
      isStringEqual(t.Name, "Other")
    );
    if (!otherTreatmentArea) {
      return "";
    }

    return (
      otherTreatmentArea.medicineType.find((m) =>
        isStringEqual(m.Name, "Other")
      )?.id || ""
    );
  }, [treatmentAreas]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const [
        widgetDetails,
        currentUserRes,
        medUsageUnitsRes,
        { fields },
      ] = await Promise.all([
        treatmentServices.fetchUpdateTreatmentWidgetDetails(id),
        dispatch(zohoActions.fetchCurrentUser()),
        zohoServices.getZohoRecords("Meds_Usage_Units", ""),
        zohoServices.getFields("Treatments"),
      ]);
      if (widgetDetails.treatment.Layout?.name === "Legacy") {
        setTreatmentIsLegacy(true);
        return;
      }

      const treatmentInfo = await treatmentServices.fetchUpdateTreatmentWidgetDetailsTreatmentInfo(
        id,
        (currentUserRes as any)?.payload?.id
      );
      setInProgressTreatmentOrders(treatmentInfo.inProgressTreatmentOrders);
      if (treatmentInfo.isBmhDoctor) {
        const userDoctor = (widgetDetails.doctors as ZohoBMHDoctor[]).find(
          (d) => d.id === treatmentInfo.doctorId
        );
        if (userDoctor) {
          setDoctor(
            userDoctor ||
              (widgetDetails.doctors as ZohoBMHDoctor[]).find((d) => !!d.User)!
          );
        }
      }
      setPreviousState(treatmentInfo.previousState);
      setTreatment(widgetDetails.treatment);
      setClient(widgetDetails.contact);
      const diagnosisField = fields.find(
        (f: any) => f.api_name === "Diagnosis"
      );
      setDiagnosisOptions(diagnosisField.pick_list_values);
      setDoctors(widgetDetails.doctors);
      setTreatmentAreas(widgetDetails.treatmentAreas);
      setDosingAdminRoutes(widgetDetails.dosingAdminRoutes);
      setDosingTypes(widgetDetails.dosingTypes);
      setMedicineAdminRoute(widgetDetails.medicineAdminRoutes);
      setSuggestedMedicines(widgetDetails.suggestedMedicines);
      setTreatmentTreatmentAreas(widgetDetails.treatmentTreatmentArea);
      setPharmacies(widgetDetails.pharmacies);
      setBacteriostaticProducts(widgetDetails.bacteriostaticProducts);
      setSupplementaryProducts(widgetDetails.supplementaryProducts);
      setSelectedSupplementaryProducts(
        widgetDetails.selectedSupplementaryProducts || []
      );
      setMedUsageUnits((medUsageUnitsRes as unknown) as ZohoMedUsageUnit[]);
      setImmediate(() => {
        setAllValuesFetched(true);
      });
    } catch (error) {
      const err = error as AxiosError;
      setFetchError(err.response?.data.message || err.message);
    } finally {
      setLoading(false);
    }
  }, [id, dispatch]);

  const submitDetails = useCallback(
    async (
      values: any,
      formErrors: FormikErrors<any>,
      setFieldTouched: FormikHelpers<any>["setFieldTouched"],
      finalize?: boolean
    ) => {
      if (!treatment) {
        return;
      }
      const payload = getUpdateTreatmentPayload(
        activeTab,
        treatment,
        values,
        initialValues,
        client!,
        user!,
        doctor,
        treatmentAreas,
        medicineAdminRoutes,
        previousState,
        otherMedicineTypeId,
        finalize
      );
      try {
        setLoading(true);
        const isOk = validateTreatmentForm(formErrors, setFieldTouched);
        if (!isOk) {
          return;
        }
        const res = await treatmentServices.updateTreatment(payload);
        if (res?.treatmentName) {
          setNewTreatmentPlan(res);
          const newTreatmentDoctorDetails = doctors.find(
            (d) => d.id === payload.data.doctorZohoId
          );
          if (newTreatmentDoctorDetails) {
            setNewTreatmentDoctor(getDoctorName(newTreatmentDoctorDetails));
          }
        } else {
          setIsSuccessWithNoNewTreatmentPlan(true);
        }
        clearSavedDefaultValues("update-treatment", id);
      } catch (error) {
        const err = error as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [
      client,
      user,
      doctor,
      treatmentAreas,
      medicineAdminRoutes,
      treatment,
      activeTab,
      initialValues,
      previousState,
      id,
      otherMedicineTypeId,
      doctors,
    ]
  );

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values, { setSubmitting, setFieldTouched, validateForm }) => {
      setSubmitting(true);
      const errors = await validateForm();
      await submitDetails(values, errors, setFieldTouched);
      setSubmitting(false);
    },
    [submitDetails]
  );

  const setFormDialog = useCallback((form: "products" | "treatment-plan") => {
    setActiveTab(form === "products" ? 1 : 0);
    setIsSelectedForm(true);
  }, []);

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
    >
      <TreatmentWidgetLayout
        cancelDialog={
          view !== "form"
            ? undefined
            : {
                title: "Cancel Update",
                text:
                  "All the data you entered will be discarded. Do you want to continue updating the treatment?",
                proceedButtonText: "CANCEL AND DISCARD ALL DATA",
                cancelButtonText: "CONTINUE UPDATING",
              }
        }
        defaultWidth={view === "form-dialog" || view === "success" ? 500 : 1000}
      >
        <TreatmentWidgetTitle
          title="Update Treatment Plan"
          isCustomButton={isCustomButton}
        />
        <TreatmentWidgetContent>
          <Loader open={loading} />
          {view === "fetch-error" ? (
            <Alert severity="error">
              <AlertTitle>An error occurred while fetching data.</AlertTitle>
              {fetchError}
            </Alert>
          ) : null}

          {view === "initial-block" ? (
            <BlockWidgetAlert
              treatmentIsLegacy={treatmentIsLegacy}
              isDoctorNotAllowed={isDoctorNotAllowed}
              missingRequiredFields={missingRequiredFields}
            />
          ) : null}
          <DefaultValuesStore widgetName="update-treatment" identifier={id} />
          {savedDefaultValues && allValuesFetched ? (
            <SetInitialValues savedDefaultValues={savedDefaultValues} />
          ) : null}
          {view === "success" &&
          (newTreatmentPlan || isSuccessWithNoNewTreatmentPlan) ? (
            <NewTreatmentPlanInfo
              newTreatmentDoctor={newTreatmentDoctor}
              doctor={doctor}
              newTreatmentPlan={newTreatmentPlan}
              hasPaidTreatmentOrders={hasPaidTreatmentOrders}
              paidTreatmentOrdersMapped={paidTreatmentOrdersMapped}
              isSuccessWithNoNewTreatmentPlan={isSuccessWithNoNewTreatmentPlan}
            />
          ) : null}
          {view === "form" && !allValuesFetched ? (
            <Box height={700}>{/*  */}</Box>
          ) : null}

          {view === "form-dialog" ? <FormsDialog /> : null}

          {view === "form" && allValuesFetched ? (
            <UpdateTreatmentForm
              treatmentAreas={treatmentAreas}
              dosingTypes={dosingTypes}
              dosingAdminRoutes={dosingAdminRoutes}
              medicineAdminRoutes={medicineAdminRoutes}
              doctor={doctor}
              doctors={doctors}
              client={client}
              submitButtonText="UPDATE TREATMENT PLAN"
              hasPaidTreatmentOrders={hasPaidTreatmentOrders}
              treatment={treatment}
              inProgressTreatmentOrders={inProgressTreatmentOrders}
              activeTab={activeTab}
              previousState={previousState}
              pharmacies={pharmacies}
              suggestedMedicines={suggestedMedicines}
              treatmentTreatmentAreasById={treatmentTreatmentAreasById}
              bacteriostaticProducts={bacteriostaticProducts}
              supplementaryProducts={supplementaryProducts}
              setValuesChangeCalculation={setValuesChangeCalculation}
              paidTreatmentOrders={paidTreatmentOrders}
              otherMedicineTypeId={otherMedicineTypeId}
              medUsageUnits={medUsageUnits}
              diagnosisOptions={diagnosisOptions}
              selectedSupplementaryProducts={selectedSupplementaryProducts}
            />
          ) : null}
        </TreatmentWidgetContent>

        <UpdateTreatmentActions
          view={view}
          onSubmit={submitDetails}
          doctor={doctor}
          createdTreatmentUrl={newTreatmentPlan?.treatmentUrl}
          submitButtonText="UPDATE TREATMENT PLAN"
          activeTab={activeTab}
          setFormDialog={setFormDialog}
          otherMedicineTypeId={otherMedicineTypeId}
          isSuccessWithNoNewTreatmentPlan={isSuccessWithNoNewTreatmentPlan}
        />
      </TreatmentWidgetLayout>
    </Formik>
  );
};
