import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";
import { TitleValue } from "components/TitleValue";
import {
  BillingCyclePeriod,
  DoseVolume,
  OnlyOnceField,
  QuantityAndPricingFields,
  SelfSupplyField,
} from "components/Treatments";
import { ProductFields } from "components/Treatments/TreatmentMedicinesAndProducts";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-material-ui";
import {
  billingCycleUnitOptions,
  getFieldName,
  getShowBacteriostatic,
  getShowInjectionBundle,
  getValueFromObject,
  isStringEqual,
  mapZohoCrmProductsToDbProducts,
} from "helpers";
import React, { useEffect, useMemo } from "react";
import {
  AutoCompleteOption,
  FinalizeMatchingProductDetails,
  ZohoMedUsageUnit,
  ZohoProduct,
  ZohoTreatmentTreatmentArea,
} from "types";

const useStyles = makeStyles(({ spacing, palette: p }) => ({
  cheapest: {
    color: colors.green["500"],
    fontWeight: "bolder",
  },
  productWrapper: {
    border: `1px solid ${p.grey[200]}`,
    borderRadius: spacing(0.5),
    padding: spacing(2),
  },
}));

interface Props {
  product: FinalizeMatchingProductDetails;
  name?: string;
  productsUrl: string;
  isCheapest: boolean;
  isBestBillingProduct: boolean;
  treatmentTreatmentArea: ZohoTreatmentTreatmentArea;
  bacteriostaticProducts: ZohoProduct[];
  productFieldName: string;
  medUsageUnits: Record<string, ZohoMedUsageUnit>;
  isSelectedMatchingProductsDefaultBillingCyclesFailed: boolean;
  isMatchingProductVisible: boolean;
  productsLength: number;
  matchingProductsLength: number;
}

export const MatchingProduct = ({
  product,
  name = "",
  productsUrl,
  isCheapest,
  isBestBillingProduct,
  treatmentTreatmentArea,
  bacteriostaticProducts,
  productFieldName,
  medUsageUnits,
  isSelectedMatchingProductsDefaultBillingCyclesFailed,
  isMatchingProductVisible,
  productsLength,
  matchingProductsLength,
}: Props) => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();

  const selectedProduct: string = useMemo(
    () => getValueFromObject(productFieldName, values),
    [values, productFieldName]
  );

  const billingCycleUnit: AutoCompleteOption = useMemo(
    () => getValueFromObject("billingCycleUnit", values),
    [values]
  );

  const productUrl = useMemo(() => {
    return productsUrl.replace(
      "xxxxx",
      product.matchingProduct.Product?.id || ""
    );
  }, [productsUrl, product.matchingProduct.Product?.id]);

  const billingCycle = useMemo(() => {
    if (product.billingCycle.includes(" 1 ")) {
      if (product.billingCycle.includes("Month")) {
        return "Month";
      }
      if (product.billingCycle.includes("Week")) {
        return "Week";
      }
      return "Day";
    }

    return product.billingCycle.replace("Every", "");
  }, [product.billingCycle]);

  const injectionBundlesProducts = useMemo(() => {
    return mapZohoCrmProductsToDbProducts(
      (product.injectionBundles || []).filter(
        (p) => p.Pharmacy.id === product.matchingProduct.product.Pharmacy.id
      ),
      medUsageUnits
    );
  }, [
    product.injectionBundles,
    product.matchingProduct.product.Pharmacy.id,
    medUsageUnits,
  ]);

  const bacteriostaticProductsOptions = useMemo(() => {
    return mapZohoCrmProductsToDbProducts(
      (bacteriostaticProducts || []).filter(
        (p) => p.Pharmacy.id === product.matchingProduct.product.Pharmacy.id
      ),
      medUsageUnits
    );
  }, [
    bacteriostaticProducts,
    product.matchingProduct.product.Pharmacy.id,
    medUsageUnits,
  ]);

  const showInjectionBundle = useMemo(() => {
    return getShowInjectionBundle(
      product.treatmentMedicine.Dosing_Type?.name,
      treatmentTreatmentArea?.Treatment_Area?.name
    );
  }, [
    product.treatmentMedicine.Dosing_Type?.name,
    treatmentTreatmentArea?.Treatment_Area?.name,
  ]);

  const showBact = useMemo(() => {
    return getShowBacteriostatic(
      product.treatmentMedicine.Dosing_Type?.name,
      treatmentTreatmentArea?.Treatment_Area?.name
    );
  }, [
    product.treatmentMedicine.Dosing_Type?.name,
    treatmentTreatmentArea?.Treatment_Area?.name,
  ]);

  const selectedPharmacies = useMemo(() => {
    const pharmacy = product.matchingProduct.product.Pharmacy;
    return [
      {
        title: pharmacy.name,
        value: pharmacy.id,
      },
    ];
  }, [product.matchingProduct.product.Pharmacy]);

  const productQuantity = useMemo(() => {
    return `${product.matchingProduct.Quantity} X ${
      product.matchingProduct.product.Usage_Units_Per_Sellable_Unit || ""
    } ${product.matchingProduct.product.Med_Usage_Unit?.name}`;
  }, [
    product.matchingProduct.Quantity,
    product.matchingProduct.product.Usage_Units_Per_Sellable_Unit,
    product.matchingProduct.product.Med_Usage_Unit?.name,
  ]);

  const productBillingCycle = useMemo(() => {
    return product.matchingProduct.Billing_Cycle_Unit;
  }, [product.matchingProduct.Billing_Cycle_Unit]);

  useEffect(() => {
    setImmediate(() => {
      if (
        selectedProduct === product.id &&
        matchingProductsLength === 1 &&
        !isStringEqual(billingCycleUnit?.value, productBillingCycle) &&
        isMatchingProductVisible
      ) {
        setFieldValue(
          "billingCycleUnit",
          billingCycleUnitOptions.find((b) =>
            isStringEqual(b.value, productBillingCycle)
          )
        );
      }
    });
  }, [
    setFieldValue,
    selectedProduct,
    billingCycleUnit?.value,
    productBillingCycle,
    isSelectedMatchingProductsDefaultBillingCyclesFailed,
    product.id,
    isMatchingProductVisible,
    matchingProductsLength,
  ]);

  return (
    <Box>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={6}>
          <FormControl>
            <Field component={RadioGroup} name={productFieldName}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value={product.id}
                label={
                  <Typography>
                    <a href={productUrl} target="_blank" rel="noreferrer">
                      {product.matchingProduct.product?.Product_Name}
                    </a>
                  </Typography>
                }
              />
            </Field>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TitleValue title="Dose:" value={product.dose} />
        </Grid>
        {selectedProduct === product.id &&
        isSelectedMatchingProductsDefaultBillingCyclesFailed ? (
          <>
            <Grid item>
              <BillingCyclePeriod
                name={name}
                allowChangeBillingCycleUnit
                maxBillingCyclePeriodWidth={120}
              />
            </Grid>
            <Grid item>
              <OnlyOnceField name={name} />
            </Grid>
            <QuantityAndPricingFields
              name={name}
              unitPrice={Number(product.unitPrice)}
              medUsageUnitName={
                product.matchingProduct.product.Med_Usage_Unit?.name
              }
              unitsPerSellableUnit={
                product.matchingProduct.product.Usage_Units_Per_Sellable_Unit
              }
              quantityMaxWidth={100}
            />
          </>
        ) : (
          <>
            <Grid item xs={3}>
              <TitleValue
                title="Billing Cycle:"
                value={`Every ${billingCycle}`}
              />
            </Grid>
            <Grid item xs={3}>
              <TitleValue title="Quantity:" value={productQuantity} />
            </Grid>
            <QuantityAndPricingFields
              name={name}
              unitPrice={Number(product.unitPrice)}
              quantity={product.matchingProduct.Quantity}
            />
          </>
        )}

        {productsLength > 1 && (isCheapest || isBestBillingProduct) ? (
          <Grid item xs={3}>
            <Typography variant="body2" className={classes.cheapest}>
              {isBestBillingProduct ? "Best Billing" : ""}
              {isBestBillingProduct && isCheapest ? " - " : ""}
              {isCheapest ? "Cheapest" : ""}
            </Typography>
          </Grid>
        ) : null}

        <Grid item xs={3}>
          <TitleValue title="Pharmacy:" value={product.pharmacy} />
        </Grid>
        {product.doseVolume ? (
          <Grid item xs={3}>
            <TitleValue
              title="Dose Volume:"
              value={<DoseVolume doseVolume={product.doseVolume} />}
            />
          </Grid>
        ) : null}

        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <SelfSupplyField name={name} />
        </Grid>
      </Grid>
      {showInjectionBundle ? (
        <Box mt={4} className={classes.productWrapper}>
          <Box mb={2}>
            <Typography variant="subtitle2">Injection Bundle</Typography>
          </Box>
          <ProductFields
            name={getFieldName("injectionBundle", name)}
            products={injectionBundlesProducts || []}
            dosingType={product.treatmentMedicine.Dosing_Type.name}
            isInjectionBundle
            initialSelectedPharmacies={selectedPharmacies}
            selectedPharmacies={selectedPharmacies}
          />
        </Box>
      ) : null}
      {showBact ? (
        <Box mt={2} className={classes.productWrapper}>
          <Box mb={2}>
            <Typography variant="subtitle2">Bacteriostatic Water</Typography>
          </Box>
          <ProductFields
            name={getFieldName("bacteriostatic", name)}
            products={bacteriostaticProductsOptions}
            dosingType={product.treatmentMedicine.Dosing_Type.name}
            isBacteriostatic
            initialSelectedPharmacies={selectedPharmacies}
            selectedPharmacies={selectedPharmacies}
          />
        </Box>
      ) : null}
    </Box>
  );
};
