import React from "react";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ZohoModuleEnum } from "@deep-consulting-solutions/bmh-constants";

import { ZohoUserProfileEnum } from "types";
import { ROUTES } from "configs";
import NetworkErrorDialog from "components/NetworkErrorDialog";
import PathwayGenerateForm from "widgets/PathwayGenerateForm";
import PathwayListGenerateForm from "widgets/PathwayListGenerateForm";
import PathwayPrintTubeLabels from "widgets/PathwayPrintTubeLabels";
import PathwayListPrintTubeLabels from "widgets/PathwayListPrintTubeLabels";
import PathwaySendInstructions from "widgets/PathwaySendInstructions";
import PathwaySendInstructionsBlueprint from "widgets/PathwaySendInstructionsBlueprint";
import Unmatched from "widgets/Unmatched";
import TestResults, { TestResultInPathway } from "widgets/TestResults";
import ServiceRoute, { RouteServiceEnum } from "components/ServiceRoute";
import { TestOBXCodes } from "widgets/Settings/TestOBXCodes";
import { TestFailureCodes } from "widgets/Settings/TestFailureCodes";
import { TestAbnormalFlags } from "widgets/Settings/TestAbnormalFlags";
import { TestTubesQtyTab } from "widgets/Settings/TestTubesQtyTab";
import { EmailConfigTab } from "widgets/Settings/EmailConfigTab";
import { PDFTemplates } from "widgets/Settings/PDFTemplates";
import { MCMAvailability } from "widgets/Settings/MCMAvailability";
import { AssignMcm } from "widgets/AssignMcm";
import {
  PathwaySpecifyDetailsBlueprint,
  PathwaySpecifyDetails,
} from "widgets/PathwaySpecifyDetailsBlueprint";
import { ContactEmailLogs } from "widgets/ContactEmailLogs";
import { SettingsEmailLogs } from "widgets/SettingsEmailLogs";
import { TestProfileDelete } from "widgets/TestProfileDelete";
import { ClientAdmin } from "widgets/ClientAdmin";
import { ConfirmKitPacking } from "widgets/ConfirmKitPacking";
import { WaiveChargingClientForRetest } from "widgets/WaiveChargingClientForRetest";
import { ConfirmKitInvoiceCreation } from "widgets/ConfirmKitInvoiceCreation";
import { ConfirmInvoiceCreation } from "widgets/ConfirmInvoiceCreation";
import {
  RetestRequiredClientError,
  RetestRequiredNotClientError,
  GenerateInvoice,
  ApproveChargingClientForRetest,
} from "widgets/RetestRequired";
import { PathwayCancelRetestArragementBlueprint } from "widgets/PathwayCancelRetestArragementBlueprint";
import { ComparisonWidget } from "widgets/Unmatched/ComparisonWidget";
import * as PendingBloodDrawerInvoiceBluePrint from "widgets/PendingBloodDrawerInvoiceBluePrint";
import {
  CompleteTreatmentPlan,
  CreateTreatmentPlan,
  UpdateTreatmentPlan,
  CreateVenesectionTreatment,
  FinalizeTreatment,
  RenewTreatment,
  PharmacySelectionAutomation,
  RequestChanges,
  StartPrescriptionRenewal,
} from "widgets/Treatments";
import { SignCttp } from "widgets/SignCttp";
import { SignVenesectionTreatment } from "widgets/SignVenesectionTreatment";
import { MigrateTreatment } from "widgets/Treatments/MigrateTreatment";
import { CreateManualOrder } from "widgets/CreateManualOrder";
import { PauseTreatment } from "widgets/Treatments/PauseTreatment";
import { ResumeTreatment } from "widgets/ResumeTreatment/ResumeTreatment";
import { CancelTreatment } from "widgets/Treatments/CancelTreatment";
import { CancelTreatmentOrder } from "widgets/CancelTreatmentOrder";
import { CreateReplacmentOrder } from "widgets/CreateReplacementOrder";
import { MedicationDisposal } from "widgets/MedicationDisposal";
import { CreateManualPrescription } from "widgets/CreateManualPrescription";
import { RegularCheckup } from "widgets/RegularCheckup";
import { BulkOrderPacking } from "widgets/BulkOrderPacking";
import { BulkOrderManifest } from "widgets/BulkOrderManifest";
import { ConfirmOrderPacking } from "widgets/ConfirmOrderPacking";
import {
  AddProductVariant,
  DispenseOrder,
  PerformFinalChecks,
  PrintPackingSlip,
  ProvideClarifications,
  RequestClarificationFromDoctor,
} from "widgets/DispenseDrugs";
import { CompleteOrderDetails } from "widgets/CompleteOrderDetails";
import { DocumentsWidget } from "widgets/DocumentsWidget";
import { WaiveShippingCharges } from "widgets/WaiveShippingCharges";
import { CheckoutPage } from "widgets/CheckoutPage";
import { SettingsRecordAccessLogs } from "widgets/Settings/SettingsRecordAccessLogs";
import { RecordAccessLogs } from "widgets/RecordAccessLogs";
import { UpdateUpcomingOrders } from "widgets/UpdateUpcomingOrders";
import { NotifyDoctorToSignDoc } from "widgets/NotifyDoctorToSignDoc";

export default () => {
  return (
    <Router>
      <CssBaseline />
      <Switch>
        <ServiceRoute
          path={ROUTES.pathway.details.generateForm}
          component={PathwayGenerateForm}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
        />
        <ServiceRoute
          path={ROUTES.pathway.details.generateFormBlueprint}
          render={(props) => <PathwayGenerateForm isBlueprint {...props} />}
          init
          entity
          services={RouteServiceEnum.records}
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
        />
        <ServiceRoute
          path={ROUTES.pathway.details.sendInstructions}
          component={PathwaySendInstructions}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
          services={RouteServiceEnum.records}
        />
        <ServiceRoute
          path={ROUTES.pathway.details.sendInstructionsBlueprint}
          component={PathwaySendInstructionsBlueprint}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
          services={RouteServiceEnum.records}
        />
        <ServiceRoute
          path={ROUTES.pathway.details.sendNSInstructionsBlueprint}
          render={(props) => (
            <PathwaySendInstructionsBlueprint isNurseService {...props} />
          )}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
          services={RouteServiceEnum.records}
        />
        <ServiceRoute
          path={ROUTES.pathway.details.specifyDetails}
          component={PathwaySpecifyDetails}
          init
          entity
          services={RouteServiceEnum.records}
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
        />
        <ServiceRoute
          path={ROUTES.pathway.details.specifyDetailsBlueprint}
          component={PathwaySpecifyDetailsBlueprint}
          init
          entity
          services={RouteServiceEnum.records}
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
        />
        <ServiceRoute
          path={ROUTES.pathway.details.cancelRetestArrangementBlueprint}
          component={PathwayCancelRetestArragementBlueprint}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
        />

        <ServiceRoute
          path={ROUTES.pathway.details.tubeLabel}
          component={PathwayPrintTubeLabels}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
        />
        <ServiceRoute
          path={ROUTES.pathway.list.generateForm}
          component={PathwayListGenerateForm}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
        />
        <ServiceRoute
          path={ROUTES.pathway.list.tubeLabel}
          component={PathwayListPrintTubeLabels}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
        />
        <ServiceRoute
          path={ROUTES.unmatched}
          component={Unmatched}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.UNMATCHED_HL7_FILES,
          }}
        />
        <ServiceRoute
          path={ROUTES.unmatchedComparisonWidget}
          component={ComparisonWidget}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.UNMATCHED_HL7_FILES,
          }}
        />
        <ServiceRoute
          exact
          path={ROUTES.testResult.list}
          component={TestResults}
          init
          entity
          services={RouteServiceEnum.currentUser}
          onlyForTabs={{
            tabs: ZohoModuleEnum.CONTACTS,
          }}
        />
        <ServiceRoute
          exact
          path={ROUTES.testResult.details.retestRequired.notClientError}
          component={RetestRequiredNotClientError}
          init
          entity
        />
        <ServiceRoute
          exact
          path={ROUTES.testResult.details.retestRequired.clientError}
          component={RetestRequiredClientError}
          init
          entity
        />
        <ServiceRoute
          exact
          path={
            ROUTES.testResult.details.retestRequired
              .approveChargingClientForRetest
          }
          component={ApproveChargingClientForRetest}
          init
          entity
        />
        <ServiceRoute
          exact
          path={ROUTES.testResult.details.retestRequired.generateInvoice}
          component={GenerateInvoice}
          init
          entity
        />
        <ServiceRoute
          exact
          path={ROUTES.pendingBloodDrawerInvoiceBluePrint.generateInvoice}
          component={PendingBloodDrawerInvoiceBluePrint.GenerateInvoice}
          init
          entity
        />
        <ServiceRoute
          exact
          path={
            ROUTES.pendingBloodDrawerInvoiceBluePrint.confirmKitInvoiceCreation
          }
          component={PendingBloodDrawerInvoiceBluePrint.ConfirmInvoiceCreation}
          init
          entity
        />
        <ServiceRoute
          path={ROUTES.pathway.testResult}
          component={TestResultInPathway}
          init
          entity
          services={RouteServiceEnum.currentUser}
          onlyForTabs={{
            tabs: ZohoModuleEnum.PATHWAYS,
          }}
        />

        <ServiceRoute
          exact
          path={ROUTES.settings.obx}
          component={TestOBXCodes}
          init
        />
        <ServiceRoute
          exact
          path={ROUTES.settings.failure}
          component={TestFailureCodes}
          init
        />
        <ServiceRoute
          exact
          path={ROUTES.settings.abnormal}
          component={TestAbnormalFlags}
          init
        />
        <ServiceRoute
          exact
          path={ROUTES.settings.tubes}
          component={TestTubesQtyTab}
          init
        />
        <ServiceRoute
          exact
          path={ROUTES.settings.emailConfig}
          component={EmailConfigTab}
          init
        />
        <ServiceRoute
          exact
          path={ROUTES.settings.templates}
          component={PDFTemplates}
          init
        />
        <ServiceRoute
          exact
          path={ROUTES.settings.mcm}
          component={MCMAvailability}
          init
        />
        <ServiceRoute
          exact
          path={ROUTES.settings.mcmCall}
          component={() => <p>Follow Up PCM Call</p>}
          init
        />
        <ServiceRoute
          exact
          path={ROUTES.contacts.assignMcm}
          component={AssignMcm}
          init
          onlyForTabs={{
            tabs: [ZohoModuleEnum.CONTACTS, ZohoModuleEnum.MEETINGS],
          }}
          services={RouteServiceEnum.records}
        />
        <ServiceRoute
          path={ROUTES.clientAdmin}
          component={ClientAdmin}
          exact
          init
          entity
          notForProfiles={ZohoUserProfileEnum.BMH_DOCTORS}
        />
        <ServiceRoute
          exact
          path={ROUTES.contacts.emailLogs}
          component={ContactEmailLogs}
          init
          entity
          onlyForTabs={{
            tabs: ZohoModuleEnum.CONTACTS,
          }}
          notForProfiles={ZohoUserProfileEnum.BMH_DOCTORS}
        />
        <Route
          exact
          path={ROUTES.settings.emailLogs}
          component={SettingsEmailLogs}
        />
        <ServiceRoute
          exact
          path={ROUTES.testProfileDelete}
          component={TestProfileDelete}
          init
          entity
        />
        <ServiceRoute
          exact
          path={ROUTES.confirmKitPacking}
          component={ConfirmKitPacking}
          init
          entity
        />
        <ServiceRoute
          init
          exact
          entity
          component={ConfirmInvoiceCreation}
          services={RouteServiceEnum.records}
          path={ROUTES.confirmInvoiceCreation}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={WaiveChargingClientForRetest}
          path={ROUTES.waiveChargingClientForRetest}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={ConfirmKitInvoiceCreation}
          path={ROUTES.confirmKitInvoiceCreation}
        />
        <ServiceRoute
          init
          exact
          entity
          component={MigrateTreatment}
          path={ROUTES.treatments.migrate}
        />
        <ServiceRoute
          init
          exact
          entity
          component={CreateTreatmentPlan}
          path={ROUTES.treatments.create}
        />
        <ServiceRoute
          init
          exact
          entity
          component={CompleteTreatmentPlan}
          path={ROUTES.treatments.completeForBlueprint}
        />
        <ServiceRoute
          init
          exact
          entity
          component={() => <CompleteTreatmentPlan isCustomButton />}
          path={ROUTES.treatments.complete}
        />
        <ServiceRoute
          init
          exact
          entity
          component={UpdateTreatmentPlan}
          path={ROUTES.treatments.updateForBlueprint}
        />
        <ServiceRoute
          init
          exact
          entity
          component={() => <UpdateTreatmentPlan isCustomButton />}
          path={ROUTES.treatments.update}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={CreateVenesectionTreatment}
          path={ROUTES.createVenesectionTreatment}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={SignCttp}
          path={ROUTES.signCttp}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={SignVenesectionTreatment}
          path={ROUTES.signVenesectionTreatment}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={CreateManualOrder}
          path={ROUTES.createManualOrder}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={PauseTreatment}
          path={ROUTES.pauseTreatment}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={ResumeTreatment}
          path={ROUTES.resumeTreatment}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={CancelTreatment}
          path={ROUTES.cancelTreatment}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={CancelTreatmentOrder}
          path={ROUTES.cancelTreatmentOrder}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={CreateReplacmentOrder}
          path={ROUTES.createReplacmentOrder}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={MedicationDisposal}
          path={ROUTES.medicationDisposal}
        />
        <ServiceRoute
          init
          exact
          entity
          component={FinalizeTreatment}
          path={ROUTES.treatments.finalize}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={CreateManualPrescription}
          path={ROUTES.createManualPrescription}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={RegularCheckup}
          path={ROUTES.clientCheckup}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={RenewTreatment}
          path={ROUTES.renewTreatment}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={BulkOrderPacking}
          path={ROUTES.bulkOrderPacking}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={BulkOrderManifest}
          path={ROUTES.bulkOrderManifest}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={ConfirmOrderPacking}
          path={ROUTES.confirmOrderPacking}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={UpdateUpcomingOrders}
          path={ROUTES.updateUpcomingOrders}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={DispenseOrder}
          path={ROUTES.dispenseDrugs.dispenseOrder}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={PerformFinalChecks}
          path={ROUTES.dispenseDrugs.performFinalChecks}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={CompleteOrderDetails}
          path={ROUTES.completeOrderDetails}
        />
        <ServiceRoute
          init
          exact
          entity
          services={RouteServiceEnum.records}
          component={WaiveShippingCharges}
          path={ROUTES.waiveShippingCharges}
        />
        <ServiceRoute
          init
          exact
          entity
          component={PrintPackingSlip}
          path={ROUTES.dispenseDrugs.printPackingSlip}
        />
        <ServiceRoute
          init
          exact
          entity
          component={AddProductVariant}
          path={ROUTES.dispenseDrugs.addProductVariant}
        />
        <ServiceRoute
          init
          exact
          entity
          component={DocumentsWidget}
          path={ROUTES.documents}
        />
        <ServiceRoute
          init
          exact
          entity
          component={PharmacySelectionAutomation}
          path={ROUTES.treatments.startPharmacySelection}
        />
        <ServiceRoute
          init
          exact
          entity
          component={RequestClarificationFromDoctor}
          path={ROUTES.dispenseDrugs.requestDoctorClarification}
        />
        <ServiceRoute
          init
          exact
          entity
          component={ProvideClarifications}
          path={ROUTES.dispenseDrugs.provideClarifications}
        />
        <ServiceRoute
          init
          exact
          entity
          component={RequestChanges}
          path={ROUTES.treatments.requestChanges}
        />
        <ServiceRoute
          init
          exact
          entity
          component={StartPrescriptionRenewal}
          path={ROUTES.treatments.startPrescriptionRenewal}
        />
        <ServiceRoute
          init
          exact
          component={CheckoutPage}
          path={ROUTES.settings.checkoutPage}
        />
        <ServiceRoute
          init
          exact
          component={SettingsRecordAccessLogs}
          path={ROUTES.settings.recordAccessLogs}
        />
        <ServiceRoute
          init
          exact
          entity
          services={[RouteServiceEnum.records]}
          component={RecordAccessLogs}
          path={ROUTES.recordAccessLogs}
        />
        <ServiceRoute
          init
          exact
          entity
          component={NotifyDoctorToSignDoc}
          path={ROUTES.notifyDoctor}
        />
      </Switch>

      <NetworkErrorDialog />
    </Router>
  );
};
