import { DBPharmacyProduct, TreatmentOrderItemFields } from "types";
import moment from "moment";
import {
  BarcodeData,
  BarcodeType,
  Decision,
  decisionMap,
  DispenseOrderDetails,
  DispenseOrderPages,
  MedicationCategory,
  MissingState,
  ParentOrderItem,
  ProductItemVariant,
  ProductType,
  TreatmentOrder,
  TreatmentOrderItem,
} from "widgets/DispenseDrugs";
import * as Yup from "yup";
import JSPDF from "jspdf";
import "jspdf-autotable";
import { BarcodeScanner } from "@deep-consulting-solutions/barcode-scanner/dist";
import { dispenseDrugsServices, notifications } from "services";

const stringRequired = Yup.string().required("This field is required");
const positiveNumberNullable = Yup.number()
  .positive("Must be a positive number")
  .nullable()
  .typeError("Must be a positive number");

const missingQuantityConditional = positiveNumberNullable.when(
  "quantity",
  (quantity, schema) =>
    schema.max(quantity, "Missing quantity cannot be greater than the quantity")
);

const stringNullableDefaultEmpty = Yup.string()
  .nullable()
  .default("")
  .typeError("Must be a string");

const variantSchema = Yup.object().shape({
  batchNumber: stringRequired.typeError("Batch number must be a string"),
  expiryDate: Yup.date()
    .required("Expiry date is required")
    .typeError("Expiry date must be a valid date"),
  manufacturer: stringRequired
    .default("")
    .typeError("Manufacturer must be a string"),
});

const replacementQuantityConditional = Yup.number()
  .positive("Must be a positive number")
  .nullable()
  .when("missingState", {
    is: (val) => val === "replace",
    then: Yup.number()
      .required(
        'Replacement quantity is required when missing state is "replace"'
      )
      .positive("Must be a positive number"),
    otherwise: positiveNumberNullable,
  });

const replacementConditional = Yup.mixed()
  .nullable()
  .when("missingState", {
    is: "replace",
    then: Yup.mixed().required(
      'Replacement is required when missing state is "replace"'
    ),
  });
const replacementUsageNotesConditional = Yup.string().when("missingState", {
  is: "replace",
  then: Yup.string().required(
    'Replacement usage notes are required when missing state is "replace"'
  ),
  otherwise: Yup.string().nullable(),
});
const needleSchema = Yup.object().when("productType", {
  is: ProductType.INJECTION_BUNDLE,
  then: Yup.object()
    .shape({
      title: stringRequired,
      value: stringRequired,
    })
    .typeError("Needle is required"),
  otherwise: Yup.object().nullable(),
});

const extraNeedleSchema = Yup.object().when(
  ["productType", "extraInjectionType", "extraInjectionVolume"],
  {
    is: (productType, extraInjectionType, extraInjectionVolume) =>
      productType === ProductType.INJECTION_BUNDLE &&
      extraInjectionType &&
      extraInjectionVolume,
    then: Yup.object()
      .shape({
        title: stringRequired,
        value: stringRequired,
      })
      .typeError("Needle is required"),
    otherwise: Yup.object().nullable(),
  }
);

const needleFilterSchema = Yup.object().when("productType", {
  is: ProductType.INJECTION_BUNDLE,
  then: Yup.object()
    .shape({
      title: stringRequired,
      value: stringRequired,
    })
    .typeError("Needle is required"),
  otherwise: Yup.object().nullable(),
});

const extraNeedleFilterSchema = Yup.object().when(
  ["productType", "extraInjectionType", "extraInjectionVolume"],
  {
    is: (productType, extraInjectionType, extraInjectionVolume) =>
      productType === ProductType.INJECTION_BUNDLE &&
      extraInjectionType &&
      extraInjectionVolume,
    then: Yup.object()
      .shape({
        title: stringRequired,
        value: stringRequired,
      })
      .typeError("Needle is required"),
    otherwise: Yup.object().nullable(),
  }
);

export const getDispenseOrderValidationSchema = (
  currentFormView: DispenseOrderPages
) => {
  const baseSchema = {
    variants: Yup.array().of(variantSchema),
    children: Yup.array()
      .of(
        Yup.object().shape({
          needle: needleSchema,
          needleFilter: needleFilterSchema,
          extraNeedle: extraNeedleSchema,
          extraNeedleFilter: extraNeedleFilterSchema,
          variants: Yup.array().of(variantSchema),
        })
      )
      .nullable(),
  };

  const fullSchema = {
    usageNotes: stringNullableDefaultEmpty,
    missingQuantity: missingQuantityConditional,
    missingState: stringNullableDefaultEmpty,
    suggestedReplacementQuantity: positiveNumberNullable,
    replacementQuantity: replacementQuantityConditional,
    replacement: replacementConditional,
    replacementUsageNotes: replacementUsageNotesConditional,
    variants: Yup.array().of(variantSchema),
    children: Yup.array()
      .of(
        Yup.object().shape({
          missingQuantity: missingQuantityConditional,
          missingState: stringNullableDefaultEmpty,
          suggestedReplacementQuantity: positiveNumberNullable,
          replacementQuantity: replacementQuantityConditional,
          replacementUsageNotes: replacementUsageNotesConditional,
          replacement: replacementConditional,
          needle: needleSchema,
          needleFilter: needleFilterSchema,
          extraNeedle: extraNeedleSchema,
          extraNeedleFilter: extraNeedleFilterSchema,
          variants: Yup.array().of(variantSchema),
        })
      )
      .nullable(),
  };

  const conditionalSchema =
    currentFormView === "handle-missing-items" ? fullSchema : baseSchema;

  const itemSchema = Yup.object().shape(conditionalSchema);

  return Yup.object().shape({
    mainItems: Yup.array().of(itemSchema),
    supplementaryItems: Yup.array().of(itemSchema),
  });
};

export const sortChildrenItems = (
  a: TreatmentOrderItem,
  b: TreatmentOrderItem
) => {
  const typeA = a.productItem.Product_Type === "Injection Bundle" ? -1 : 1;
  const typeB = b.productItem.Product_Type === "Injection Bundle" ? -1 : 1;
  if (typeA !== typeB) {
    return typeA - typeB;
  }
  return 0;
};

export const getMainAndSupplementaryItems = (data: DispenseOrderDetails) => {
  const prescriptionItemsMap = new Map(
    data.prescriptionItems.map((item) => [item.id, item])
  );
  const treatmentMedicinesMap = new Map(
    data.treatmentMedicines.map((item) => [item.id, item])
  );

  const orderItems = data.treatmentOrderItems.map((item) => {
    if (item.Quantity === item.Missing_Quantity)
      item.missingState = MissingState.SEND_TO_BMH;

    const prescriptionItem = prescriptionItemsMap.get(
      item.Prescription_Item?.id
    );
    const medicineItem = treatmentMedicinesMap.get(item.Treatment_Medicine?.id);

    item.Product_Type = item.productItem?.Product_Type;

    const newItem = { ...item };
    if (prescriptionItem) {
      newItem.remainingQuantity =
        prescriptionItem.Quantity_To_Dispense -
        prescriptionItem.Quantity_Dispensed;
    }
    if (medicineItem) {
      newItem.medicineItem = medicineItem;
    }
    return newItem;
  });

  const arrangedOrderItems = orderItems
    .filter((item) => !item.Parent_Item)
    .map((item) => {
      const children = orderItems
        .filter((m) => m.Parent_Item?.id === item.id)
        .map((k) => ({ ...k, isChild: true }));
      return {
        ...item,
        children: children.sort(sortChildrenItems),
      };
    });

  return {
    mainItems: arrangedOrderItems.filter(
      (i) => i.productItem.Product_Type !== "Supplementary"
    ),
    supplementaryItems: arrangedOrderItems.filter(
      (i) => i.productItem.Product_Type === "Supplementary"
    ),
  };
};

export const getQuantityString = (item: TreatmentOrderItem) => {
  return `${item.Quantity} ${
    item.productItem?.Usage_Units_Per_Sellable_Unit
      ? ` x ${item.productItem.Usage_Units_Per_Sellable_Unit}`
      : ""
  } ${
    item.productItem?.Usage_Unit_Volume_ml
      ? `${item.productItem.Usage_Unit_Volume_ml}ml`
      : ""
  } ${item.productItem?.Med_Usage_Unit?.name || ""}`;
};

export const getDurationString = (item: TreatmentOrderItem) => {
  return `${item.Duration_Period} ${item.Duration_Unit || ""}`;
};

export const getMedsUsageInstructions = (item: TreatmentOrderItem) => {
  if (!item.medicineItem) return "";

  if (item.medicineItem?.Specify_Custom_Dosing_Instructions) {
    return item.medicineItem.Custom_Dosing_Instructions || "";
  }

  const {
    Cyclical_Use_Days: cUseDays,
    Cyclical_Stop_Days: cStopDays,
    Dosing_Amount: dAmount,
    Dosing_Unit: dUnit,
    Dosing_Type: dType,
    Administration_Route: aRoute,
    Other_Administration_Route: oARoute,
    Body_Part: bPart,
    Number_of_Use_X: nOUX,
    Frequency_of_Use: fOU,
    Time_of_Day: tOD,
    Temporary_Use_Days: tUD,
    As_Required_If_Needed: aRIN,
  } = item.medicineItem;

  let itemDosing = "";

  let administrationRoute = "";
  if (aRoute?.name || oARoute) {
    administrationRoute = ` ${aRoute?.name || oARoute || ""}`;
  }
  const bodyPart = bPart ? ` (${bPart})` : ``;
  const frequencyOfUse = fOU?.includes(`'X'`)
    ? `${fOU
        .replace(`'X'`, nOUX?.toString())
        .toLowerCase()
        .replace("day", nOUX > 1 ? "days" : "day")}`.replace("dayss", "days")
    : `${nOUX || ""} ${fOU || ""}`.toLowerCase();
  let timeOfDay = "";
  if (tOD.length) {
    let timesString = "";
    if (tOD.length < 2) {
      timesString = tOD.join(", ");
    } else {
      const lastDay = tOD[tOD.length - 1];
      const firstParts = tOD.slice(0, tOD.length - 1);
      timesString = `${firstParts.join(", ")} and ${lastDay}`;
    }
    timeOfDay = ` in the ${timesString}`.toLowerCase();
  }
  let useFor = "";
  if (cUseDays && cStopDays) {
    useFor = ` Use for ${cUseDays} day${
      (cUseDays || 1) > 1 ? "s" : ""
    } then Stop for ${cStopDays} day${(cStopDays || 1) > 1 ? "s" : ""}`;
  } else if (tUD) {
    useFor = ` For ${tUD} day${(tUD || 1) > 1 ? "s" : ""}`;
  } else if (aRIN) {
    useFor = " As Required";
  }

  itemDosing = `${dAmount || ""}${
    dUnit?.name || ""
  } ${administrationRoute}${bodyPart}, ${
    dType?.name || ""
  } ${frequencyOfUse}${timeOfDay}.${useFor}`;

  return (
    itemDosing ||
    `${item.medicineItem?.Dosing_Amount || ""} ${
      item.medicineItem?.Dosing_Unit?.name || ""
    } ${item.medicineItem?.Dosing_Type?.name || ""} ${
      item.medicineItem?.Administration_Route?.name || ""
    } ${
      item.medicineItem?.Frequency_of_Use.includes("Every")
        ? ""
        : item.medicineItem?.Number_of_Use_X || ""
    } ${
      item.medicineItem?.Frequency_of_Use.includes("Every")
        ? item.medicineItem?.Frequency_of_Use.replace(
            "'X'",
            item.medicineItem?.Number_of_Use_X?.toString()
          )
        : item.medicineItem?.Frequency_of_Use || ""
    } ${item.medicineItem?.Time_of_Day?.join("|") || ""} ${
      item.medicineItem?.Cyclical_Use_Days
        ? `${item.medicineItem?.Cyclical_Use_Days} days use/ `
        : ""
    } ${
      item.medicineItem?.Cyclical_Stop_Days
        ? `${item.medicineItem?.Cyclical_Stop_Days} days break`
        : ""
    }`
  );
};

const getNeedleTitleAndValue = (needle: string | null) => {
  if (!needle) return null;
  return {
    title: needle,
    value: needle,
  };
};

const getProductByZohoCrmId = (
  id: string | null,
  replacements: DBPharmacyProduct[]
) => {
  const product = replacements.find((item) => item.ZohoCrmId === id);

  if (product) {
    return {
      title: product.NameForPrescription,
      value: product.ZohoCrmId,
    };
  }
  return {
    title: "",
    value: "",
  };
};

export const getInitialItemsValues = (
  items: ParentOrderItem[],
  replacements: DBPharmacyProduct[]
) => {
  return items.map((item) => {
    const missingState = decisionMap[item.Decision as Decision] || "";
    return {
      usageNotes: item.Usage_Note,
      quantity: item.Quantity,
      allUnitSameBatchNumber: false,
      missingQuantity: item.Missing_Quantity || null,
      productType: item.productItem?.Product_Type || "",
      medicationCategory: item.productItem?.Medication_Category || "",
      missingState,
      suggestedReplacementQuantity: item.Suggested_Replacement_Quantity || null,
      replacementQuantity: item.Replacement_Quantity || null,
      dispensingNotes: item.Dispensing_Note || "",
      replacementUsageNotes: item.Replacement_Usage_Notes || "",
      suggestedReplacement: getProductByZohoCrmId(
        item.Suggested_Replacement?.id || "",
        replacements
      ),
      replacement: getProductByZohoCrmId(
        item.Replacement?.id || "",
        replacements
      ),
      variants: item.Dispensed_Unit.length
        ? item.Dispensed_Unit.map((unit) => ({
            batchNumber: unit.Batch_Number,
            expiryDate: unit.Expiry_Date,
            manufacturer: unit.productVariant?.Manufacturer,
            id: unit.Product_Variant?.id,
            Product: unit.productVariant?.Product?.id,
          }))
        : [],
      ...(item.children.length > 0 && {
        children: item.children.map((child) => {
          const childMissingState =
            decisionMap[child.Decision as Decision] || "";
          return {
            quantity: child.Quantity,
            includeAlcoholWipes: child.Include_Alcohol_Wipes,
            productType: child.productItem?.Product_Type || "",
            medicationCategory: child.productItem?.Medication_Category || "",
            needle: getNeedleTitleAndValue(child.Needle),
            needleFilter: getNeedleTitleAndValue(child.Needle_Filter),
            extraNeedle: getNeedleTitleAndValue(child.Extra_Needle),
            extraNeedleFilter: getNeedleTitleAndValue(
              child.Extra_Needle_Filter
            ),
            allUnitSameBatchNumber: false,
            extraInjectionType: child.productItem.Extra_Injection_Type || "",
            extraInjectionVolume:
              child.productItem.Extra_Syringe_Volume_ml || "",
            missingQuantity: child.Missing_Quantity || null,
            missingState: childMissingState,
            suggestedReplacementQuantity:
              child.Suggested_Replacement_Quantity || null,
            replacementQuantity: child.Replacement_Quantity || null,
            dispensingNotes: child.Dispensing_Note || "",
            replacementUsageNotes: child.Replacement_Usage_Notes || "",
            suggestedReplacement: getProductByZohoCrmId(
              child.Suggested_Replacement?.id || "",
              replacements
            ),
            replacement: getProductByZohoCrmId(
              child.Replacement?.id || "",
              replacements
            ),
            variants: child.Dispensed_Unit.length
              ? child.Dispensed_Unit.map((unit) => ({
                  batchNumber: unit.Batch_Number,
                  expiryDate: unit.Expiry_Date,
                  manufacturer: unit.productVariant?.Manufacturer,
                  id: unit.Product_Variant?.id,
                  Product: unit.productVariant?.Product?.id,
                }))
              : [],
          };
        }),
      }),
    };
  });
};

export const getInjectionBundleQuantityString = (parent: ParentOrderItem) => {
  let doses = 0;
  if (parent?.productItem?.Med_Usage_Unit.name === "Ampoule") {
    doses = parent.productItem.Usage_Units_Per_Sellable_Unit * parent.Quantity;
  } else {
    doses =
      (parent?.productItem?.Dosage_Per_Usage_Unit *
        parent?.productItem?.Usage_Units_Per_Sellable_Unit *
        parent?.Quantity) /
      (parent?.medicineItem?.Dosing_Amount || 1);
  }
  return `${doses ? doses + 2 : 2} (${doses || 2} doses + 2 extra)`;
};

export const getAlcoholWipesQuantityString = (
  parent: ParentOrderItem,
  numberRes?: boolean
) => {
  let doses = 0;
  if (parent?.productItem?.Med_Usage_Unit.name === "Ampoule") {
    doses = parent.productItem.Usage_Units_Per_Sellable_Unit * parent.Quantity;
  } else {
    doses =
      (parent?.productItem?.Dosage_Per_Usage_Unit *
        parent?.productItem?.Usage_Units_Per_Sellable_Unit *
        parent?.Quantity) /
      (parent?.medicineItem?.Dosing_Amount || 1);
  }
  if (!doses) doses = 2;
  const finalValue = doses % 2 === 0 ? (doses + 2) / 2 : (doses + 1) / 2;
  if (numberRes) return finalValue;
  return `${finalValue} pack${finalValue > 1 ? "s" : ""} (${
    doses || 2
  } doses + ${finalValue % 2 === 0 ? "2" : "1"} extra)`;
};

export const getAllItems = (
  mainItems: ParentOrderItem[],
  supplementaryItems: TreatmentOrderItem[]
) => {
  return [
    ...mainItems,
    ...mainItems.reduce((prev: TreatmentOrderItem[], curr) => {
      if (curr.children?.length) {
        return prev.concat(
          curr.children.map((child) => ({ ...child, isChild: true }))
        );
      }
      return prev;
    }, []),
    ...supplementaryItems,
  ] as any;
};

export const matchReplacementItems = (
  item: TreatmentOrderItem,
  replacement: DBPharmacyProduct
) => {
  if (item.productItem?.Product_Type === ProductType.INJECTION_BUNDLE) {
    return replacement.ProductType === ProductType.INJECTION_BUNDLE;
  }
  if (item.productItem?.Product_Type === ProductType.SUPPLEMENTARY) {
    return replacement.ProductType === ProductType.SUPPLEMENTARY;
  }
  return (
    replacement.MedicineTypes?.Name === item.productItem?.Medicine_Type?.name
  );
};

export const getShouldSendToBMH = (
  mainItemsValue: any,
  supplementaryItemsValue: any,
  mainItems: any,
  supplementaryItems: any
) => {
  const allItemsValue: TreatmentOrderItemFields[] = getAllItems(
    mainItemsValue,
    supplementaryItemsValue
  );
  const allItems: TreatmentOrderItem[] = getAllItems(
    mainItems as ParentOrderItem[],
    supplementaryItems
  );
  const validItems = allItems.filter((_, i) => {
    return (
      allItemsValue[i].missingQuantity &&
      _.productItem?.Medication_Category === MedicationCategory.PRESCRIPTION
    );
  });

  if (validItems.length) {
    return validItems.every((item, index) => {
      const missingQuantity = allItemsValue[index].missingQuantity;
      const quantity = item.Quantity;
      return missingQuantity === quantity;
    });
  }
  return false;
};

const getCrmProduct = (product: DBPharmacyProduct) => {
  return {
    ...product,
    Usage_Units_Per_Sellable_Unit: product.UsageUnitsPerSellableUnit,
    Med_Usage_Unit: product.MedsUsageUnitName,
    Name_For_Prescription: product.ProductName,
  };
};

export const getMedicineLabelsData = (
  values: any,
  mainItems: TreatmentOrderItem[],
  supplementaryItems: TreatmentOrderItem[],
  replacements: DBPharmacyProduct[],
  treatmentOrder: TreatmentOrder
) => {
  const shouldIgnoreMissingItems = [
    DispenseOrderPages.CONFIRM_DISPENSING,
    DispenseOrderPages.MEDICINE_LABELS,
  ].includes(values.currentPage);
  const mainItemsValue = values.mainItems;
  const supplementaryItemsValue = values.supplementaryItems;
  const allItemsValue: TreatmentOrderItemFields[] = getAllItems(
    mainItemsValue,
    supplementaryItemsValue
  );
  const allItems: TreatmentOrderItem[] = getAllItems(
    mainItems as ParentOrderItem[],
    supplementaryItems
  );
  const labels: any[] = [];
  allItemsValue.forEach((item, index) => {
    let qty = 0;
    let product: any = allItems[index].productItem;
    if (item.missingState === MissingState.REPLACE) {
      const dbProduct = replacements.find(
        (i) => i.ZohoCrmId === item.replacement?.value
      );
      product = dbProduct ? getCrmProduct(dbProduct) : product;
      qty = item.replacementQuantity || 0;
    } else if (
      [
        MissingState.MARK_AS_OWED,
        MissingState.DISPENSE_PARTIALLY,
        MissingState.SEND_TO_BMH,
      ].includes(item.missingState as any)
    ) {
      qty = (item.quantity || 0) - (item.missingQuantity || 0);
    } else {
      qty = item.quantity || 0;
    }
    if (shouldIgnoreMissingItems) {
      qty = item.quantity || 0;
    }

    let dispensedUnits: {
      Batch_Number: string;
      Expiry_Date: string;
      Manufacturer: string;
    }[] = [];
    if (item.allUnitSameBatchNumber && item.variants?.length) {
      const newDispensedUnit = Array.from(Array(qty)).map(() => {
        return {
          Batch_Number: item.variants?.[0]?.batchNumber || "",
          Expiry_Date: item.variants?.[0]?.expiryDate || "",
          Manufacturer: item.variants?.[0]?.manufacturer || "",
        };
      });
      dispensedUnits = newDispensedUnit;
    } else {
      dispensedUnits = (item.variants || []).reduce(
        (prev: any, curr) =>
          prev.concat({
            Batch_Number: curr?.batchNumber || "",
            Expiry_Date: curr?.expiryDate || "",
            Manufacturer: curr?.manufacturer || "",
          }),
        []
      );
    }

    dispensedUnits.forEach((unit, count) => {
      if (unit?.Expiry_Date && unit?.Batch_Number && count < qty) {
        const itemDetails = allItems[index];
        let usageNote = "";
        if (item.missingState === MissingState.REPLACE) {
          usageNote = item.replacementUsageNotes || "";
        } else {
          usageNote = item.usageNotes || "";
        }

        const model = {
          UUPSU: itemDetails.isChild
            ? allItems.find((o) => o.id === itemDetails.Parent_Item?.id)
                ?.productItem?.Usage_Units_Per_Sellable_Unit
            : product?.Usage_Units_Per_Sellable_Unit,
          MUU: product?.Med_Usage_Unit?.name,
          TOIPN: product?.Name_For_Prescription,
          TOIUN: itemDetails.Usage_Instructions,
          TOIDA:
            item.missingState === "replace"
              ? ""
              : itemDetails.medicineItem?.Dosing_Amount,
          TOIDU:
            item.missingState === "replace"
              ? ""
              : itemDetails.medicineItem?.Dosing_Unit?.name,
          TOIDT:
            item.missingState === "replace"
              ? ""
              : itemDetails.medicineItem?.Dosing_Type?.name,
          TOIAR:
            item.missingState === "replace"
              ? ""
              : itemDetails.medicineItem?.Administration_Route?.name,
          TOINU: "",
          TOIFU: "",
          DUBN: unit?.Batch_Number,
          DUED: moment(unit?.Expiry_Date).format("DD/MM/YYYY"),
          USGN: usageNote,
          IW: product?.Warnings,
          PFN: treatmentOrder?.Patient?.name,
          TOIP: itemDetails.Pharmacy_Name,
          PA: itemDetails.Pharmacy_Address,
          PP: itemDetails.Pharmacy_Phone,
          PW: itemDetails.Pharmacy_URL,
          DATE: moment(new Date()).format("DD/MM/YYYY"),
        };
        labels.push(model);
      }
    });
  });
  return labels;
};

function hasEnoughSpace(contentHeight: number, doc: JSPDF) {
  const availableSpace =
    doc.internal.pageSize.height - (doc as any).autoTable.previous.finalY;
  return availableSpace > contentHeight;
}

export const getChangeNote = (
  values: any,
  mainItems: TreatmentOrderItem[],
  supplementaryItems: TreatmentOrderItem[],
  replacements: DBPharmacyProduct[],
  treatmentOrder: TreatmentOrder
) => {
  const changeNote: any = new JSPDF({
    orientation: "p",
    unit: "mm",
    format: "a4",
    putOnlyUsedFonts: true,
    floatPrecision: 16,
  });

  const fontSize = 12;
  changeNote.setFontSize(fontSize);
  const textStyles = { fontSize, cellPadding: 0 };

  const styles = {
    lineWidth: 0.1,
    lineColor: [0, 0, 0],
    fontSize,
  };

  const columnStyles = {
    0: { columnWidth: 13 },
    1: { columnWidth: 90 },
    2: { columnWidth: 30 },
  };

  const getText = (text: string, startY: number, checkEnd?: boolean) => {
    return {
      startY,
      body: [[text]],
      theme: "plain",
      styles: textStyles,
      margin: 40,
      didDrawPage: checkEnd
        ? (data: any) => {
            if (!hasEnoughSpace(data.settings.margin.bottom, changeNote)) {
              changeNote.addPage();
            }
          }
        : undefined,
    };
  };

  const mainItemsValue = values.mainItems;
  const supplementaryItemsValue = values.supplementaryItems;
  const allItemsValue: TreatmentOrderItemFields[] = getAllItems(
    mainItemsValue,
    supplementaryItemsValue
  );
  const allItems: TreatmentOrderItem[] = getAllItems(
    mainItems as ParentOrderItem[],
    supplementaryItems
  );

  changeNote.autoTable(
    getText(
      `Dear ${treatmentOrder?.Patient?.name},\n\nYou will notice that your package is different than usual.\n\nYour original package includes the following items:`,
      15
    )
  );

  const table1 = allItems.map((i, index) => {
    return [
      index + 1,
      i.productItem?.Name_For_Prescription || i.Prescription_Name || "",
      `${i.Quantity || ""} ${i.Sellable_Unit || ""}`,
    ];
  });

  changeNote.autoTable({
    startY: (changeNote.lastAutoTable.finalY as number) + 5,
    head: [["S/N", "Product", "Quantity"]],
    body: table1,
    theme: "plain",
    styles,
    columnStyles,
    margin: 40,
  });

  const replacementsItems = allItems
    .map((rItem, index) => ({ ...rItem, rItemIndex: index }))
    .filter((_, index) => allItemsValue[index].missingState === "replace");

  changeNote.autoTable(
    getText(
      `Some of your normal medicines were not available and couldn't be sent to you in this package.${
        replacementsItems.length
          ? "\n\nTo make sure there is no interruption to your Hormone Therapy, you received replacement medication:"
          : ""
      }`,
      (changeNote.lastAutoTable.finalY as number) + 5
    )
  );

  if (replacementsItems.length) {
    const table2 = replacementsItems.map((i, index) => {
      const replacementName =
        allItemsValue[i.rItemIndex].replacement?.title ||
        replacements.find(
          (j) => j.ZohoCrmId === allItemsValue[i.rItemIndex].replacement?.value
        )?.ProductName;

      return [
        index + 1,
        `${replacementName || ""} (Replaced ${
          i.productItem?.Name_For_Prescription || i.Prescription_Name || ""
        })`,
        `${allItemsValue[i.rItemIndex].replacementQuantity || ""} ${
          i.Sellable_Unit || ""
        }`,
      ];
    });

    changeNote.autoTable({
      startY: (changeNote.lastAutoTable.finalY as number) + 5,
      head: [["S/N", "Product", "Quantity"]],
      body: table2,
      theme: "plain",
      styles,
      columnStyles,
      margin: 40,
      didDrawPage: (data: any) => {
        if (!hasEnoughSpace(data.settings.margin.bottom, changeNote)) {
          changeNote.addPage();
        }
      },
    });
  }

  const missingItems = allItems
    .map((mItem, index) => ({ ...mItem, mItemIndex: index }))
    .filter(
      (_, index) =>
        allItemsValue[index].missingQuantity &&
        [
          MissingState.DISPENSE_PARTIALLY,
          MissingState.SEND_TO_BMH,
          MissingState.MARK_AS_OWED,
        ].includes((allItemsValue[index].missingState || "") as MissingState)
    );

  if (replacementsItems.length || missingItems.length) {
    changeNote.autoTable(
      getText(
        `${
          replacementsItems.length
            ? "Please make sure to carefully read and follow the usage instructions on the box of your replacement medicine! They might be different from the instructions of use of your previous medication."
            : ""
        }${
          missingItems.length
            ? `${
                replacementsItems.length ? "\n\n" : ""
              }We know getting your medicine on time is crucial, so you will receive all of your remaining medicine in a second package as soon as they are available.\n\nYour next package will include all your remaining medicine missing from this package:`
            : ""
        }`,
        (changeNote.lastAutoTable.finalY as number) + 5,
        true
      )
    );
  }

  if (missingItems.length) {
    const table3 = missingItems.map((i, index) => {
      return [
        index + 1,
        `${i.productItem?.Name_For_Prescription || i.Prescription_Name || ""}`,
        `${allItemsValue[i.mItemIndex].missingQuantity || ""} ${
          i.Sellable_Unit || ""
        }`,
      ];
    });

    changeNote.autoTable({
      startY: (changeNote.lastAutoTable.finalY as number) + 5,
      head: [["S/N", "Product", "Quantity"]],
      body: table3,
      theme: "plain",
      styles,
      columnStyles,
      margin: 40,
      didDrawPage: (data: any) => {
        if (!hasEnoughSpace(data.settings.margin.bottom, changeNote)) {
          changeNote.addPage();
        }
      },
    });
  }

  changeNote.autoTable(
    getText(
      `We are sorry for any inconvenience this may have caused!\n\nIf you have any questions, please don't hesitate to contact your BMH Personal Case Manager.\n\nThank you,\nBalance My Hormones and The Hormonist Pharmacy`,
      (changeNote.lastAutoTable.finalY as number) + 5,
      true
    )
  );

  changeNote.setFillColor(255, 255, 255);

  const output = changeNote.output("datauristring");

  return output;
};

export const getSendToBMHPayload = (
  values: any,
  mainItems: TreatmentOrderItem[],
  supplementaryItems: TreatmentOrderItem[],
  treatmentOrder: TreatmentOrder
) => {
  const mainItemsValue = values.mainItems;
  const supplementaryItemsValue = values.supplementaryItems;
  const allItemsValue: TreatmentOrderItemFields[] = getAllItems(
    mainItemsValue,
    supplementaryItemsValue
  );
  const allItems: TreatmentOrderItem[] = getAllItems(
    mainItems as ParentOrderItem[],
    supplementaryItems
  );
  return {
    treatmentOrderId: treatmentOrder.id,
    orderItems: allItemsValue.reduce(
      (prev: any[], curr, index) =>
        prev.concat({
          id: allItems[index].id,
          missingQuantity: curr.missingQuantity || 0,
          decision: "Send to BMH",
          suggestedReplacement:
            typeof curr.suggestedReplacement?.value === "string"
              ? curr.suggestedReplacement.value
              : null,
          suggestedQuantity: curr.suggestedReplacementQuantity,
          dispensingNote: curr.dispensingNotes,
        }),
      []
    ),
  };
};

const getDecision = (missingState: string) => {
  switch (missingState) {
    case MissingState.DISPENSE_PARTIALLY:
      return "Dispense Partially and Mark Rest as Owed";
    case MissingState.REPLACE:
      return "Replace";
    case MissingState.MARK_AS_OWED:
      return "Mark as Owed";
    default:
      return "Dispense Partially and Send to BMH";
  }
};

export const getDispensePayload = (
  values: any,
  mainItems: TreatmentOrderItem[],
  supplementaryItems: TreatmentOrderItem[],
  treatmentOrder: TreatmentOrder,
  addMissingQuantity: boolean
) => {
  const mainItemsValue = values.mainItems;
  const supplementaryItemsValue = values.supplementaryItems;
  const allItemsValue: TreatmentOrderItemFields[] = getAllItems(
    mainItemsValue,
    supplementaryItemsValue
  );
  const allItems: TreatmentOrderItem[] = getAllItems(
    mainItems as ParentOrderItem[],
    supplementaryItems
  );
  return {
    treatmentOrderId: treatmentOrder.id,
    orderItems: allItemsValue.reduce((prev: any[], curr, index) => {
      const itemDetails = allItems[index];
      let finalQty = 0;
      if (addMissingQuantity) {
        if (curr.missingState === MissingState.REPLACE) {
          finalQty = curr.replacementQuantity || 0;
        } else {
          finalQty = (curr.quantity || 0) - (curr.missingQuantity || 0);
        }
      } else {
        finalQty = curr.quantity || 0;
      }
      const allVariants = curr.allUnitSameBatchNumber
        ? Array.from(Array(finalQty)).map(() => curr.variants?.[0])
        : curr.variants;

      return prev.concat({
        id: allItems[index].id,
        missingQuantity: addMissingQuantity ? curr.missingQuantity || 0 : 0,
        ...(addMissingQuantity
          ? { decision: getDecision(curr.missingState || "") }
          : {}),
        ...(addMissingQuantity && curr.missingState === MissingState.SEND_TO_BMH
          ? {
              suggestedReplacement:
                typeof curr.suggestedReplacement?.value === "string"
                  ? curr.suggestedReplacement.value
                  : null,
              suggestedReplacementQuantity: curr.suggestedReplacementQuantity,
              dispensingNote: curr.dispensingNotes,
              sendToBMH: true,
            }
          : {}),
        ...(addMissingQuantity && curr.missingState === MissingState.REPLACE
          ? {
              replacement:
                typeof curr.replacement?.value === "string"
                  ? curr.replacement.value
                  : null,
              replacementQuantity: curr.replacementQuantity,
              replacementUsageNotes: curr.replacementUsageNotes,
              replace: true,
            }
          : {}),
        ...(addMissingQuantity &&
        curr.missingState === MissingState.MARK_AS_OWED
          ? { markAsOwed: true }
          : {}),
        ...(addMissingQuantity &&
        curr.missingState === MissingState.DISPENSE_PARTIALLY
          ? { dispenseParially: true }
          : {}),
        ...(addMissingQuantity && values.shouldSendToBMH
          ? { sendToBmh: true }
          : {}),
        ...(curr.usageNotes ? { Usage_Note: curr.usageNotes } : {}),
        ...(curr.needle ? { Needle: curr.needle.value } : {}),
        ...(curr.needleFilter
          ? { Needle_Filter: curr.needleFilter.value }
          : {}),
        ...(curr.includeAlcoholWipes
          ? { Include_Alcohol_Wipes: curr.includeAlcoholWipes }
          : {}),
        ...(curr.extraNeedle ? { Extra_Needle: curr.extraNeedle.value } : {}),
        ...(curr.extraNeedleFilter
          ? { Extra_Needle_Filter: curr.extraNeedleFilter.value }
          : {}),
        ...(curr.includeAlcoholWipes
          ? {
              Alcohol_Wipes_Quantity: getAlcoholWipesQuantityString(
                allItems.find(
                  (o) => o.id === itemDetails.Parent_Item?.id
                ) as ParentOrderItem,
                true
              ),
            }
          : {}),
        variants: allVariants?.map((variant) => {
          const currentVariant = itemDetails.productItem.variants?.find(
            (vItem) => vItem.id === variant?.id
          );
          return {
            batchNumber: variant?.batchNumber,
            expiryDate: moment(variant?.expiryDate).format("YYYY-MM-DD"),
            manufacturer: variant?.manufacturer,
            Product:
              curr.missingState === MissingState.REPLACE
                ? curr.replacement?.value
                : itemDetails.Item.id,
            ...(currentVariant?.id ? { id: currentVariant.id } : {}),
          };
        }),
      });
    }, []),
  };
};

export const activateScanner = (
  onScanSuccessTrigger: (payload: any) => Promise<void> | void,
  onScanErrorTrigger: (payload: any) => void
) => {
  const barcodeScanner = new BarcodeScanner();
  barcodeScanner.activateScanner({
    onScan: (payload) => {
      onScanSuccessTrigger(payload);
    },
    onScanError: (errors) => {
      onScanErrorTrigger(errors);
    },
  });
};

export const deactivateScanner = () => {
  const barcodeScanner = new BarcodeScanner();
  if (barcodeScanner) {
    barcodeScanner.deActivateScanner();
  }
};

const checkIfProductWithGTINExist = async (gtin: string) => {
  const products = await dispenseDrugsServices.fetchProductByGTIN(gtin);
  if (products.length > 0) {
    notifications.notifyError(
      "Product is not being dispensed. Make sure you are scanning the correct product"
    );
  } else {
    notifications.notifyError(
      "Barcode doesn't match any products in the system. Go to Products to add missing product or update the right product with this barcode."
    );
  }
};

const checkIfProductWithBarcodeExist = async (barcode: string) => {
  const products = await dispenseDrugsServices.fetchProductByBarcode(barcode);
  if (products.length > 0) {
    notifications.notifyError(
      "Product is not being dispensed. Make sure you are scanning the correct product"
    );
  } else {
    notifications.notifyError(
      "Barcode doesn't match any products in the system. Go to Products to add missing product or update the right product with this barcode."
    );
  }
};

export const getDataForScan = async (
  mainItems: TreatmentOrderItem[],
  mainItemsValue: TreatmentOrderItemFields[],
  supplementaryItems: TreatmentOrderItem[],
  supplementaryItemsValue: TreatmentOrderItemFields[],
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  data?: BarcodeData
) => {
  if (!data) return;
  if (data.expiryDate) {
    const parts = data.expiryDate.split("/");
    const day = parts[0];
    const month = parts[1];
    const year = `20${parts[2]}`;
    const originalDate = new Date(`${year}-${month}-${day}`);
    data.expiryDate = moment(originalDate).format("YYYY-MM-DD");
  }

  let productVariant: ProductItemVariant = {} as ProductItemVariant;
  const findIndexFn = (i: TreatmentOrderItem) => {
    const xItem = i.productItem.variants?.find((o) => o.GTIN === data.GTIN);
    if (xItem) productVariant = xItem;
    return xItem;
  };

  const findBarcodeIndexFn = (i: TreatmentOrderItem) => {
    const xItem = i.productItem.variants?.find(
      (o) => o.Barcode === data.barcode
    );
    if (xItem) productVariant = xItem;
    return xItem;
  };

  let fieldName = "";

  if (data.barcodeType === BarcodeType.GS1) {
    let itemType = "mainItems";
    let orderItemIndex = mainItems?.findIndex(findIndexFn);
    let parentIndex = -1;
    if (orderItemIndex === -1) {
      orderItemIndex = supplementaryItems?.findIndex(findIndexFn);
      if (orderItemIndex !== -1) {
        itemType = "supplementaryItems";
        fieldName = `supplementaryItems.${orderItemIndex}.variants`;
      }
    } else {
      fieldName = `mainItems.${orderItemIndex}.variants`;
    }
    if (orderItemIndex === -1) {
      let count = 0;
      /* eslint-disable no-restricted-syntax */
      for (const parent of mainItems) {
        orderItemIndex = (parent as ParentOrderItem).children?.findIndex(
          findIndexFn
        );
        if (orderItemIndex !== -1) {
          parentIndex = count;
          itemType = "child";
          fieldName = `mainItems.${count}.children.${orderItemIndex}.variants`;
          break;
        }
        count += 1;
      }
    }

    if (orderItemIndex !== -1) {
      let orderItemValue: TreatmentOrderItemFields | undefined;
      if (itemType === "mainItems") {
        orderItemValue = mainItemsValue?.[orderItemIndex];
      } else if (itemType === "supplementaryItems") {
        orderItemValue = supplementaryItemsValue[orderItemIndex];
      } else {
        orderItemValue =
          mainItemsValue?.[parentIndex]?.children?.[orderItemIndex];
      }
      let finalQty = 0;
      if (orderItemValue?.missingState === MissingState.REPLACE) {
        finalQty = orderItemValue.replacementQuantity || 0;
      } else {
        finalQty =
          (orderItemValue?.quantity || 0) -
          (orderItemValue?.missingQuantity || 0);
      }
      for (let i = 0, len = finalQty; i < len; i += 1) {
        const item = orderItemValue?.variants?.[i];
        if (orderItemValue && !item && !orderItemValue?.variants) {
          const fieldValue = [
            {
              id: productVariant.id,
              manufacturer: productVariant.Manufacturer,
              expiryDate: data.expiryDate || "",
              batchNumber: data.batchNumber || "",
            },
          ];
          setFieldValue(fieldName, fieldValue);
          return;
        }
        if ((orderItemValue && !item) || !item?.manufacturer) {
          const fieldValue = {
            id: productVariant.id,
            manufacturer: productVariant.Manufacturer,
            expiryDate: data.expiryDate || "",
            batchNumber: data.batchNumber || "",
          };
          fieldName += `.${i}`;
          setFieldValue(fieldName, fieldValue);
          return;
        }

        if (i === len - 1) {
          notifications.notifyError(
            "Batch numbers and expiry dates are filled for this product. Clear. batch number(s) and try again"
          );
        }
      }
    } else {
      await checkIfProductWithGTINExist(data.GTIN || "");
    }
  }

  if (data.barcodeType === BarcodeType.OTHER) {
    let itemType = "mainItems";
    let orderItemIndex = mainItems?.findIndex(findBarcodeIndexFn);
    let parentIndex = -1;
    if (orderItemIndex === -1) {
      orderItemIndex = supplementaryItems?.findIndex(findBarcodeIndexFn);
      if (orderItemIndex !== -1) {
        itemType = "supplementaryItems";
        fieldName = `supplementaryItems.${orderItemIndex}.variants`;
      }
    } else {
      fieldName = `mainItems.${orderItemIndex}.variants`;
    }
    if (orderItemIndex === -1) {
      let count = 0;
      for (const parent of mainItems) {
        orderItemIndex = (parent as ParentOrderItem).children?.findIndex(
          findBarcodeIndexFn
        );
        if (orderItemIndex !== -1) {
          parentIndex = count;
          itemType = "child";
          fieldName = `mainItems.${count}.children.${orderItemIndex}.variants`;
          break;
        }
        count += 1;
      }
    }

    if (orderItemIndex !== -1) {
      let orderItemValue: TreatmentOrderItemFields | undefined;
      if (itemType === "mainItems") {
        orderItemValue = mainItemsValue[orderItemIndex];
      } else if (itemType === "supplementaryItems") {
        orderItemValue = supplementaryItemsValue[orderItemIndex];
      } else {
        orderItemValue =
          mainItemsValue?.[parentIndex]?.children?.[orderItemIndex];
      }
      for (let i = 0, len = orderItemValue?.quantity || 0; i < len; i += 1) {
        const item = orderItemValue?.variants?.[i];
        if (orderItemValue && !item && !orderItemValue?.variants) {
          const fieldValue = [
            {
              id: productVariant.id,
              manufacturer: productVariant.Manufacturer,
              expiryDate: data.expiryDate || "",
              batchNumber: data.batchNumber || "",
            },
          ];
          setFieldValue(fieldName, fieldValue);
          return;
        }
        if ((orderItemValue && !item) || !item?.manufacturer) {
          const fieldValue = {
            id: productVariant.id,
            manufacturer: productVariant.Manufacturer,
            expiryDate: data.expiryDate || "",
            batchNumber: data.batchNumber || "",
          };
          fieldName += `.${i}`;

          setFieldValue(fieldName, fieldValue);
          return;
        }

        if (i === len - 1) {
          notifications.notifyError(
            "Batch numbers and expiry dates are filled for this product. Clear. batch number(s) and try again"
          );
        }
      }
      /* eslint-enable no-restricted-syntax */
    } else {
      await checkIfProductWithBarcodeExist(data.barcode);
    }
  }
};

export const getMissingQuantity = (missingQuantity: any, currentPage: any) => {
  if (
    [
      DispenseOrderPages.HANDLE_MISSING_ITEMS,
      DispenseOrderPages.MEDICINE_LABELS_AND_CHANGE_NOTES,
    ].includes(currentPage)
  ) {
    return missingQuantity || 0;
  }
  return 0;
};
