import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AxiosError } from "axios";
import moment from "moment";
import { Box, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Loader from "components/Loader";
import {
  ClientDetails,
  DoctorField,
  DiagnosisField,
  TreatmentTreatmentAreasField,
  TreatmentTreatmentAreasMedicines,
  NumberInputField,
  DateInputField,
  TreatmentMedicines,
  ManualOrAutoProductSelection,
  CTTPSelect,
  TreatmentInstructionsField,
  NextCheckupTypeField,
  InvoiceVoidRadioField,
  MigrateTreatmentActions,
  MigrateTreatmentSuccess,
  TreatmentMedicinesInfo,
  TreatmentMedicinesWarning,
} from "components/Treatments";
import {
  TreatmentWidgetLayout,
  TreatmentWidgetTitle,
  TreatmentWidgetContent,
  TreatmentWidgetActions,
} from "layouts";
import {
  clearSavedDefaultValues,
  filterAndSortInvoices,
  getDiagnosisDefaultValue,
  getMigrateTreatmentPayload,
  getSavedDefaultValues,
  getTreatmentMigrationValidationSchema,
  isStringEqual,
} from "helpers";
import { useSelector } from "react-redux";
import { zohoSelectors } from "redux/zoho";
import { DefaultValuesStore } from "components/DefaultValuesStore";
import { notifications, treatmentServices, zohoServices } from "services";
import { ScrollToFieldError } from "components/ScrollToFieldError";
import { Formik, FormikConfig } from "formik";
import {
  ZohoTreatment,
  ZohoBMHDoctor,
  ZohoTreatmentArea,
  ZohoDosingAdminRoute,
  ZohoDosingType,
  MigrateTreatmentData,
  ZohoMedicineAdminRoute,
  DBPharmacy,
  DBPharmacyProduct,
  ZohoInvoice,
  MigratedLegacyTreatment,
  DBTreatmentArea,
  ZohoDiagnosisOption,
} from "types";
import { DataTabs } from "./DataTabs";

const dummyValues = {
  doctor: {
    title: "",
    value: "",
  },
  diagnosis: [],
  treatmentTreatmentAreas: [],
  instructions: "",
  treatmentPeriod: "",
  firstPaymentDate: null,
  treatmentMedicines: [],
  legacyCttp: "",
  manualOrAutoSelection: "",
  nextCheckupType: null,
  nextCheckupDate: null,
  invoicesWithDecision: [],
  pharmacies: [],
  billingCycleUnit: {
    title: "Every X Months",
    value: "Months",
  },
  supplementaryProducts: [],
};

export const MigrateTreatment: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [hasInProgressTreatment, setHasInProgressTreatment] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMigrated, setIsMigrated] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const [invoices, setInvoices] = useState<ZohoInvoice[]>([]);
  const [id] = useSelector(zohoSelectors.getIds);
  const [treatment] = useState<ZohoTreatment | null>(null);
  const [
    createdTreatment,
    setCreatedTreatment,
  ] = useState<MigratedLegacyTreatment>();
  const [doctors, setDoctors] = useState<ZohoBMHDoctor[]>([]);

  const [treatmentAreas, setTreatmentAreas] = useState<ZohoTreatmentArea[]>([]);
  const [dbTreatmentAreas, setDbTreatmentAreas] = useState<DBTreatmentArea[]>(
    []
  );
  const [dosingAdminRoutes, setDosingAdminRoutes] = useState<
    ZohoDosingAdminRoute[]
  >([]);
  const [medicineAdminRoutes, setMedicineAdminRoute] = useState<
    ZohoMedicineAdminRoute[]
  >([]);
  const [dosingTypes, setDosingTypes] = useState<ZohoDosingType[]>([]);
  const [pharmacies, setPharmacies] = useState<DBPharmacy[]>([]);
  const [pharmacyProducts, setPharmacyProducts] = useState<DBPharmacyProduct[]>(
    []
  );
  const [fetchedAllData, setIsFetchedAllData] = useState(false);
  const [diagnosisOptions, setDiagnosisOptions] = useState<
    ZohoDiagnosisOption[]
  >([]);

  const [doctor] = useState<ZohoBMHDoctor | null>(null);
  const [details, setDetails] = useState<MigrateTreatmentData | undefined>();

  const initialValues = useMemo(() => {
    if (!details || !fetchedAllData) {
      return dummyValues;
    }
    const savedDefaults = getSavedDefaultValues("migrate-treatment", id, [
      details.treatment.Modified_Time,
    ]);

    if (savedDefaults) {
      return savedDefaults;
    }
    const nextCheckupType = details?.treatment?.Contact?.Next_Check_up_Type;
    return {
      doctor: {
        title: "",
        value: "",
      },
      diagnosis: getDiagnosisDefaultValue(
        treatment?.Diagnosis || [],
        diagnosisOptions,
        details.treatment.Contact.Gender
      ),
      treatmentTreatmentAreas: [],
      instructions: "",
      treatmentPeriod: "",
      firstPaymentDate: moment().format("YYYY/MM/DD"),
      treatmentMedicines: [],
      legacyCttp: "",
      manualOrAutoSelection: "",
      nextCheckupType: nextCheckupType
        ? { title: nextCheckupType, value: nextCheckupType }
        : moment().format("YYYY/MM/DD"),
      nextCheckupDate:
        details?.treatment?.Contact?.Next_Check_up_Date ||
        moment().format("YYYY/MM/DD"),
      invoicesWithDecision: invoices.map(() => ""),
      pharmacies: pharmacies.map((pharmacy) => ({
        title: pharmacy.Name,
        value: pharmacy.ZohoCrmId,
      })),
      billingCycleUnit: {
        title: "Every X Months",
        value: "Months",
      },
      supplementaryProducts: [],
    };
  }, [
    treatment,
    details,
    invoices,
    pharmacies,
    id,
    fetchedAllData,
    diagnosisOptions,
  ]);

  const view = useMemo(() => {
    if (createdTreatment) {
      return "success";
    }

    if (fetchError) {
      return "fetch-error";
    }

    if (hasInProgressTreatment) {
      return "treatment-in-progress";
    }

    if (isMigrated) {
      return "migrated";
    }
    return "form";
  }, [fetchError, hasInProgressTreatment, isMigrated, createdTreatment]);

  const otherMedicineTypeId = useMemo(() => {
    const otherTreatmentArea = treatmentAreas.find((t) =>
      isStringEqual(t.Name, "Other")
    );
    if (!otherTreatmentArea) {
      return "";
    }

    return (
      otherTreatmentArea.medicineType.find((m) =>
        isStringEqual(m.Name, "Other")
      )?.id || ""
    );
  }, [treatmentAreas]);

  const fetchWidgetDetails = useCallback(async () => {
    try {
      const res = await treatmentServices.fetchMigrateTreatmentWidgetDetails(
        id
      );
      setDetails(res);
      const [
        { doctors: doctorsRes },
        { treatmentAreas: treatmentAreasRes },
        { dosingAdminRoutes: dosingAdminRoutesRes },
        { dosingTypes: dosingTypesRes },
        { medicineAdminRoutes: medicineAdminRoutesRes },
        { list: pharms },
        { list: dbTreatmentAreasRes },
        { fields },
      ] = await Promise.all([
        treatmentServices.fetchDoctors(),
        treatmentServices.fetchTreatmentAreas(),
        treatmentServices.fetchDosingAdminRoutes(),
        treatmentServices.fetchDosingTypes(),
        treatmentServices.fetchMedicineAdminRoutes(),
        treatmentServices.fetchPharmacies(),
        treatmentServices.fetchDbTreatmentAreas(),
        zohoServices.getFields("Treatments"),
      ]);
      setDoctors(doctorsRes);
      const diagnosisField = fields.find(
        (f: any) => f.api_name === "Diagnosis"
      );
      setDiagnosisOptions(diagnosisField.pick_list_values);
      setTreatmentAreas(treatmentAreasRes);
      setDosingAdminRoutes(dosingAdminRoutesRes);
      setDosingTypes(dosingTypesRes);
      setMedicineAdminRoute(medicineAdminRoutesRes);
      setDbTreatmentAreas(dbTreatmentAreasRes);

      let shippingCountry: string =
        res?.treatment?.Contact?.Shipping_Country || "";
      if (
        ["england", "uk", "scotland", "wales", "northern ireland"].includes(
          shippingCountry.toLowerCase()
        )
      ) {
        shippingCountry = "United Kingdom";
      }
      const allowedPharmacies = (pharms as DBPharmacy[]).filter(
        (pharmacy: DBPharmacy) => {
          return pharmacy.NotAllowedCountries
            ? !pharmacy.NotAllowedCountries.includes(shippingCountry) ||
                pharmacy.AllowedCountries.includes(shippingCountry)
            : pharmacy.AllowedCountries.includes(shippingCountry);
        }
      );
      setPharmacies(allowedPharmacies);

      let combinedInvoices: ZohoInvoice[] = res.invoices
        ? [...res.invoices]
        : [];

      if (res.recurringInvoices) {
        (res.recurringInvoices as any[]).forEach((recurring) => {
          const recurringInvoices =
            (recurring.invoices as any[])?.map((invoice: ZohoInvoice) => ({
              ...invoice,
              recurrence_name: recurring.recurrence_name,
              recurrence_link: recurring.recordCrmLink,
            })) || [];
          combinedInvoices.push(...recurringInvoices);
        });
      }

      combinedInvoices = filterAndSortInvoices(combinedInvoices);
      setInvoices(combinedInvoices);
      setIsFetchedAllData(true);
    } catch (error) {
      if ((error as AxiosError).response?.data?.message) {
        const msg: string = (error as AxiosError).response?.data?.message || "";
        if (msg.includes("already has a treatment")) {
          setHasInProgressTreatment(true);
          setErrorMessage(msg);
        } else if (msg.includes("migrated")) {
          setIsMigrated(true);
          setErrorMessage(msg);
        } else {
          setFetchError((error as Error).message);
        }
      } else {
        setFetchError((error as Error).message);
      }
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchPharmacyProducts = useCallback(async () => {
    try {
      const [{ products: pharmProducts }] = await Promise.all([
        treatmentServices.fetchPharmacyProducts(
          pharmacies.map((pharmacy: DBPharmacy) => pharmacy.Id)
        ),
      ]);
      setPharmacyProducts(pharmProducts);
    } catch (error) {
      notifications.notifyError("Failed to fetch pharmacy products");
    }
  }, [pharmacies]);

  const validationSchema = useMemo(() => {
    return getTreatmentMigrationValidationSchema();
  }, []);

  const treatmentTreatmentAreasById = useMemo(() => {
    return treatmentAreas.reduce((total, current) => {
      return { ...total, [current.id]: current };
    }, {});
  }, [treatmentAreas]);

  const submitDetails = useCallback(
    async (values: any) => {
      try {
        setLoading(true);
        const payload = getMigrateTreatmentPayload(
          values,
          details!,
          invoices,
          medicineAdminRoutes,
          treatmentAreas
        );
        const res = await treatmentServices.migrateTreatment(payload);

        clearSavedDefaultValues("migrate-treatment", id);
        setCreatedTreatment(res.treatment);
      } catch (error) {
        const err = error as AxiosError;
        notifications.notifyError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    },
    [treatmentAreas, medicineAdminRoutes, details, invoices, id]
  );

  const handleSubmit: FormikConfig<any>["onSubmit"] = useCallback(
    async (values, { setSubmitting }) => {
      await submitDetails(values);
      setSubmitting(false);
    },
    [submitDetails]
  );

  useEffect(() => {
    fetchWidgetDetails();
  }, [fetchWidgetDetails]);

  useEffect(() => {
    if (pharmacies.length) {
      fetchPharmacyProducts();
    }
  }, [fetchPharmacyProducts, pharmacies]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validationContext={initialValues}
      validateOnMount
      enableReinitialize
    >
      <>
        <DefaultValuesStore widgetName="migrate-treatment" identifier={id} />
        <TreatmentWidgetLayout defaultWidth={1200}>
          <TreatmentWidgetTitle title="Migrate Treatment" isCustomButton />
          <TreatmentWidgetContent>
            <Loader open={loading} />
            {view === "fetch-error" ? (
              <Alert severity="error">
                <AlertTitle>An error occurred while fetching data.</AlertTitle>
                {fetchError}
              </Alert>
            ) : null}
            {view === "treatment-in-progress" ? (
              <Alert severity="error">
                <Box dangerouslySetInnerHTML={{ __html: errorMessage }} />
              </Alert>
            ) : null}
            {view === "migrated" ? (
              <Alert severity="error">
                <Box dangerouslySetInnerHTML={{ __html: errorMessage }} />
              </Alert>
            ) : null}
            {view === "success" ? (
              <MigrateTreatmentSuccess newTreatment={createdTreatment} />
            ) : null}
            {view === "form" ? (
              <>
                <DataTabs details={details!} invoices={invoices} />
                <Box mb={2}>
                  <Typography variant="h5" component="h2">
                    New Treatment
                  </Typography>
                </Box>
                <Box mb={4}>
                  <ClientDetails client={details?.treatment?.Contact} />
                  <DoctorField doctors={doctors} doctor={doctor} />
                </Box>
                <Box mb={4}>
                  <DiagnosisField
                    client={details?.treatment?.Contact}
                    diagnosisOptions={diagnosisOptions}
                  />
                </Box>

                <Box mb={4}>
                  <Box maxWidth={600}>
                    <TreatmentTreatmentAreasField
                      treatmentAreas={treatmentAreas}
                    />
                  </Box>
                  <Box mb={2} maxWidth={600}>
                    <NumberInputField
                      name="treatmentPeriod"
                      label="Treatment Period in Months (number of months left from current treatment)"
                    />
                  </Box>
                  <Box mb={4} maxWidth={600}>
                    <DateInputField
                      name="firstPaymentDate"
                      label="First Payment Date"
                    />
                  </Box>

                  <Box mb={2}>
                    <TreatmentMedicinesWarning />
                  </Box>
                  <Box mb={4} maxWidth={650}>
                    <ManualOrAutoProductSelection />
                  </Box>

                  <Box mb={4}>
                    <CTTPSelect legacyCttps={details?.signedLegacyCTTP || []} />
                  </Box>

                  <TreatmentTreatmentAreasMedicines
                    treatmentAreas={treatmentAreas}
                    dosingTypes={dosingTypes}
                    dosingAdminRoutes={dosingAdminRoutes}
                    medicineAdminRoutes={medicineAdminRoutes}
                  />
                  <TreatmentMedicines
                    pharmProducts={pharmacyProducts}
                    pharmacies={pharmacies}
                    treatmentTreatmentAreasById={treatmentTreatmentAreasById}
                    dbTreatmentAreas={dbTreatmentAreas}
                  />

                  <Box mb={4} maxWidth={600}>
                    <TreatmentInstructionsField />
                  </Box>

                  {details?.treatment?.Contact?.Last_Check_up_type && (
                    <Box mb={2}>
                      <Typography variant="body1">
                        Last Checkup Type:{" "}
                        {details.treatment?.Contact?.Last_Check_up_type}
                      </Typography>
                      <Typography variant="body1">
                        Last Checkup Completed Date:{" "}
                        {moment(
                          details.treatment?.Contact
                            ?.Last_Check_up_Completed_Date || ""
                        ).format("DD/MM/yyyy")}
                      </Typography>
                    </Box>
                  )}

                  <Box mb={2} maxWidth={600}>
                    <NextCheckupTypeField />
                  </Box>

                  <Box mb={4} maxWidth={600}>
                    <DateInputField
                      name="nextCheckupDate"
                      label="Next Checkup Date"
                    />
                  </Box>

                  {invoices.length > 0 && (
                    <Box mb={2}>
                      <InvoiceVoidRadioField invoices={invoices} />
                    </Box>
                  )}
                </Box>
                <TreatmentMedicinesInfo
                  submitButtonText="Migrate Treatment"
                  otherMedicineTypeId={otherMedicineTypeId}
                />
              </>
            ) : null}
            <ScrollToFieldError />
          </TreatmentWidgetContent>
          <TreatmentWidgetActions>
            <MigrateTreatmentActions
              newTreatment={createdTreatment}
              view={view}
              onSubmit={submitDetails}
            />
          </TreatmentWidgetActions>
        </TreatmentWidgetLayout>
      </>
    </Formik>
  );
};
